import React, { useState, useRef, useEffect } from "react";
import "./ChatPopup.css";
import instance from "../../services/baseService";
import chatIcon from "../../images/chatIcon.svg";
import chatbox from "../../images/chatbox.png";

const ChatPopup = ({ client_id, chat_type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const chatBoxRef = useRef(null);

  useEffect(() => {
    fetchMessages();

    const interval = setInterval(() => {
      fetchMessages();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await instance.post(`/api/v1/chat/list`, {
        client_id: client_id,
        message: null,
        chat_type: chat_type,
        page: 1,
        perpage: 25,
      });

      setMessages(response.data.data.list);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      //   chatBoxRef.current.scrollTop = 0;
    }
  }, [messages, isOpen]);

  const handleSend = async () => {
    if (input.trim()) {
      try {
        const response = await instance.post(`/api/v1/chat/insert`, {
          client_id: client_id,
          message: input,
          chat_type: chat_type,
        });

        // const latestChat = await instance.post(`api/v1/chat/latest/chats`, {
        //   client_id: client_id,
        //   message: input,
        //   chat_type: chat_type,
        //   latest_chat: response.data.data.id,
        // });

        setInput("");
        fetchMessages();
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  const toggleChat = () => {
    if (isOpen) {
      setClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setClosing(false);
      }, 300); // Match with CSS transition duration
    } else {
      setIsOpen(true);
    }
  };

  const formatTime = (time) => {
    const date = new Date(time);

    let hours = date.getHours();
    const minutes = date.getMinutes();

    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedTime = `${hours}:${formattedMinutes} ${period}`;
    return formattedTime;
  };

  return (
    <>
      {isOpen ? (
        <div
          className={`position-fixed bottom-10 end-0 m-3 p-0 bg-white border rounded shadow-lg chatbotmodal ${
            closing ? "closing" : ""
          }`}
        >
          <div className="chatbox-header d-flex align-items-center justify-content-between p-2">
            <div className="d-flex align-items-center">
              <div className="avatar rounded-circle d-flex align-items-center justify-content-center me-2">
                <img src={chatIcon} alt="robot-icon"></img>
              </div>

              <h5 className="mb-0 text-white">Chat</h5>
            </div>
            <button
              className="btn btn-link text-white p-0 chat-close-button"
              onClick={toggleChat}
            >
              ×
            </button>
          </div>
          <div className="chatbox-body" ref={chatBoxRef}>
            {messages.toReversed().map((message, index) => (
              <div>
                <div
                  key={index}
                  className={`message ${
                    message.team === "2" ? "other" : "user"
                  } d-flex align-items-start mb-2`}
                >
                  <div className="avatar bg-light rounded-circle text-center d-flex align-items-center justify-content-center me-2">
                    <span role="img" aria-label="avatar" className="emoji">
                      🧑‍💻
                    </span>
                  </div>
                  <div>
                    <div className="message-text bg-light p-2 rounded">
                      {message.message}
                    </div>
                    <div className="d-flex align-items-center ">
                      <div className="text-muted chat-size mt-1 pl-2">
                        {message.created_user_profile.name}
                      </div>
                      {}
                      <div className="text-muted chat-size mt-1 pl-2">
                        {formatTime(message.created_time_formatted)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="input-group p-2 border-top">
            <input
              type="text"
              className="form-control"
              placeholder="Type your message ..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSend()}
            />
            <div className="input-group-append">
              <button className="btn btn-primary" onClick={handleSend}>
                Send
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="position-fixed bottom-10 end-0 m-3 p-3 rounded-circle bg-primary d-flex align-items-center justify-content-center messenger-icon"
          onClick={toggleChat}
        >
          <img src={chatbox} alt="robot-icon"></img>
          {/* <i className="bi bi-chat-dots"></i> */}
        </div>
      )}
    </>
  );
};

export default ChatPopup;
