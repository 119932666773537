// YearMonthContext.js
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';

// Create the context
const YearMonthContext = createContext();

// Create a provider component
export const YearMonthProvider = ({ children }) => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedMonth, setSelectedMonth] = useState(moment(new Date()).format('MM'));

  return (
    <YearMonthContext.Provider value={{ selectedYear, setSelectedYear, selectedMonth, setSelectedMonth }}>
      {children}
    </YearMonthContext.Provider>
  );
};

// Custom hook to use the YearMonthContext
export const useYearMonth = () => useContext(YearMonthContext);
