import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '../services/baseService'
import { ROUTES } from '../constants/periods'

export const getPeriods = createAsyncThunk('periods/getPeriods', async ({id, page_number}) => {
  const response = await instance.get(ROUTES.LIST_PERIODS(id, page_number))
    return response.data
  })

export const getSinglePeriod = createAsyncThunk('periods/getSinglePeriod', async ({id, client_id},) => {
  const response = await instance.get(ROUTES.SINGLE_PERIOD(id, client_id))
  return response.data
})

export const addPeriod = createAsyncThunk('periods/addPeriod', async ({client_id,current_page, ...rest}, thunkAPI) => {
  await instance.post(ROUTES.CREATE_PERIOD(client_id), {...rest})
  thunkAPI.dispatch(getPeriods({id:client_id, page_number: current_page}))
})

export const updatePeriod = createAsyncThunk('periods/updatePeriod', async ({data, page_number, id, client_id}, thunkAPI) => {
  const response = await instance.post(ROUTES.UPDATE_PERIOD(id, client_id), data)
  if(response.data) {
    thunkAPI.dispatch(getPeriods({id:client_id, page_number}))
  }
})

export const deletePeriod = createAsyncThunk('periods/deletePeriod', async ({id, client_id, page_number}, thunkAPI) => {
  const response = await instance.post(ROUTES.DELETE_PERIOD(id, client_id))
  if(response.data) {
    thunkAPI.dispatch(getPeriods({id:client_id, page_number}))
  }
})


export const periodSlice = createSlice({
  name: 'periods',
  initialState: {
    data: [],
    loading: false,
    error: null,
    client: null,
    success: false,
    successMsg: null,
    total_count: 0,
    period: {}
  },
  reducers: {
    resetStore(state, action){
      if (action.payload == 'all') {
        state.data = []
        state.loading = false
        state.client =  null
        state.total_count = 0 
        state.period = {}
      }
      state.error = null
      state.success = false
      state.successMsg = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPeriods.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(getPeriods.fulfilled, (state, action) => {
        state.data = action.payload.data?.list
        state.client = action.payload.data?.client
        state.total_count = action.payload.data?.total_count
        state.loading = false
    })
    builder.addCase(getPeriods.rejected, (state, action) => {
        state.loading = false
        state.error = 'Error occured'
    })
    builder.addCase(addPeriod.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(addPeriod.fulfilled, (state, action) => {
        state.loading = false
        state.successMsg = 'Successfully added Period'
        state.success = true
    })
    builder.addCase(addPeriod.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to Add Period'
    })
    builder.addCase(getSinglePeriod.pending, (state, action) => {
      state.loading = true
  })
  builder.addCase(getSinglePeriod.fulfilled, (state, action) => {
      state.period = action.payload.data
      state.loading = false
  })
  builder.addCase(getSinglePeriod.rejected, (state, action) => {
          state.loading = false
      state.error = 'Unable to fetch period'
  })
    builder.addCase(deletePeriod.pending, (state, action) => {
      state.loading = true
  })
  builder.addCase(deletePeriod.fulfilled, (state, action) => {
      state.loading = false
      state.successMsg = 'Successfully deleted period'
      state.success = true
  })
  builder.addCase(deletePeriod.rejected, (state, action) => {
      state.loading = false
      state.error = 'Unable to delete period'
  })
  builder.addCase(updatePeriod.pending, (state, action) => {
    state.loading = true
})
builder.addCase(updatePeriod.fulfilled, (state, action) => {
    state.loading = false
    state.successMsg = 'Successfully updated period'
    state.success = true
    state.period = {}

})
builder.addCase(updatePeriod.rejected, (state, action) => {
    state.loading = false
    state.error = 'Unable to update period'
    state.period = {}
})
  },
})
export const { resetStore } = periodSlice.actions
export default periodSlice.reducer