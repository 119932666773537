// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currency-dropdown {
    display: flex;
    align-items: center;
}

.currency-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.currency-image {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Forex/CurrencyDropdown.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".currency-dropdown {\n    display: flex;\n    align-items: center;\n}\n\n.currency-info {\n    display: flex;\n    align-items: center;\n    margin-left: 10px;\n}\n\n.currency-image {\n    width: 24px;\n    height: 24px;\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
