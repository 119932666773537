import { 
    Col,
    Card,
    Row, 
    Container,
    CardBody,
    CardHeader,
    Form,
    Button,
    InputGroup
 } from "react-bootstrap"
import { useState, useEffect } from 'react'
import { USER_SCHEMA } from "../../constants/user"
import { getRoles } from "../../slices/userReducer"
import { useSelector, useDispatch } from "react-redux"
import _  from 'lodash'
import { ROLE_PERMISSIONS } from "../../constants/user"

export default function UserForm(props) {
    const { submitFunc, isUpdate = false, user } = props
    const [userData, setUserData] = useState({name: null, email: null, phone: null, role: 0})
     

    const dispatch = useDispatch()
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const [validated, setValidated] = useState(false)
    const [errors, setErrors] = useState({})

    const roles = useSelector(state => state.user.roles)

    useEffect(() => {
        dispatch(getRoles())
    }, [])

    useEffect(() => {
        if(isUpdate && !!user.name) {
            const {name, email, phone, role} = user
            setUserData({name, email, phone, role: role.id})
            setSelectedPermissions(_.map(role.permissions, 'codename'))
        }
    },[isUpdate, user])

    useEffect(() => {
        if (Array.isArray(roles) && roles.length) {
            setSelectedPermissions(_.map(roles[0].permissions, 'codename'))
        }
    }, [roles])


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (typeof submitFunc === 'function') {
            let values = {
                name: null,
                email: null,
                phone: '', 
                user_role: null
            }

            if (!isUpdate) {
                values['password'] = null
                values['r_password'] = null
            }

            if (e.target) {
                values = {
                    name: e.target['name'].value,
                    email: e.target['email'].value,
                    phone: e.target['phone'].value, 
                    user_role: e.target['user_role'].value
                }

                if (!isUpdate) {
                    values['password'] = e.target['password'].value
                    values['r_password'] = e.target['r_password'].value
                }
            }
            try {
               
                const data = await USER_SCHEMA(isUpdate).validate(values, { abortEarly: false });
                setValidated(true)
                setErrors({});
                submitFunc(data)
            } catch (err) {
                const errData = {}
                err.inner.forEach(e => {
                    errData[e.path] = e.message
                    
                });
                setValidated(false)
                setErrors(errData);
                
            }
             window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
             });
      
          //  
        } 
    }

    const handleChange = (e) => {
        const role_id = e.target.value;
         const user_role = roles.filter(role => role.id == role_id)
         if (Array.isArray(user_role) && user_role.length) {
            setSelectedPermissions(_.map(user_role[0].permissions, 'codename'))
         }
         setUserData({...userData, role: role_id})
    }

    return (<Container>
        <Row>
            <Col 
            xl={12}
            lg={12}
            >
                <Card>
                    <CardHeader className="d-flex justify-content-between">
                        <div class="header-title">
                            <Card.Title as="h4">{isUpdate ?'Edit': 'New'} User Information</Card.Title>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div class="new-user-info">
                            <Form noValidate  onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label htmlFor="name" >Name:</Form.Label>
                                        <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            id="validationCustomName"
                                            name="name"
                                            isInvalid={!!errors.name}
                                            defaultValue={isUpdate? userData.name : ''}

                                        />
                                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Label htmlFor="phone">Mobile Number: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        defaultValue={isUpdate ? userData.phone : ''}
                                        disabled={isUpdate}
                                    />
                                </Col>
                                <Col md={6} className="mt-2">
                                    <Form.Label htmlFor="email">Email:</Form.Label>
                                    <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        id="email"
                                        name="email"
                                        isInvalid={!!errors.email}
                                        disabled={isUpdate}
                                        defaultValue={isUpdate ? userData.email : ''}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                {!isUpdate && (<><Col md={6} className="mt-2 mb-2">
                                    <Form.Label htmlFor="password">Password:</Form.Label>
                                    <InputGroup hasValidation>
                                    <Form.Control
                                        type="password"
                                        id="password"
                                        name="password"
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col md={6} className="mt-2">
                                    <Form.Label htmlFor="r_password">Repeat Password:</Form.Label>
                                    <InputGroup>
                                    <Form.Control
                                        type="password"
                                        id="r_password"
                                        name="r_password"isInvalid={!!errors.r_password}
                                    />
                                        <Form.Control.Feedback type="invalid">{errors.r_password}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col></>)}
                            </Row>
                            <hr/>
                            <Row>
                                <Col 
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                <div class="header-title">
                                    <Card.Title as="h5">Roles & Permissions</Card.Title>
                                </div>
                                </Col>
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                        <Form.Label htmlFor="user_role">User Role:</Form.Label>
                                        <Form.Select
                                           
                                            name="user_role" 
                                            onChange={handleChange} 
                                            value={userData.role}
                                            >
                                            {
                                                roles.map(role => {
                                                    return  <option value={role.id}>{role.name}</option>
                                                })
                                            }
                                        </Form.Select>
                                </Col>
                                <Col className="mt-4">
                                    <Row>
                                        {Object.keys(ROLE_PERMISSIONS). map(role_key => {
                                            return <Col md={4}>
                                            <Form.Check 
                                            type="checkbox" 
                                            label={ROLE_PERMISSIONS[role_key]} 
                                            disabled 
                                            checked={selectedPermissions.includes(role_key)}
                                            />
                                        </Col>
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-4"> 
                                <Col md={2}>
                                    <Button type="submit" variant="primary">{isUpdate ? 'Edit': 'Add New'} User</Button>
                                </Col>
                            </Row>
                            </Form>    
                        </div>    
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>)
}