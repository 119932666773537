import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import NoteModal from "./common/NoteModal";
import "./XlsxTable.css";
import instance from "../services/baseService";
import { useYearMonth } from "./YearMonth";
import toast from "react-hot-toast";
import {  useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

function XlsxTableRefund({reloadSheet}) {
  const {selectedMonth, selectedYear, id: userId } = useSelector((state) => state.common);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [allCells, setAllCells] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(1);
  const [modalInfo, setModalInfo] = useState({
    isVisible: false,
    cell: null,
    note: "",
    noteArr: []
  });
  const { client_id} = useParams();
  const getSheet = async () => {
    try {
      setLoading(true);
      const res = await instance.post(`/api/v1/client/refund/view/sheet/${client_id}`, {
        year: selectedYear,
        month: selectedMonth,
      });
      if(res.data.data.sheet ===  null){
        setData([]);
      }
      setFileUrl(res.data.data.sheet?.url);
      setAllCells(res.data.data.all_cell);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const getExcelCellName = ({ row, col }) => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let colName = "";
    while (col >= 0) {
      colName = letters[col % 26] + colName;
      col = Math.floor(col / 26) - 1;
    }

    const rowName = (row + 1).toString();
    return `${colName}${rowName}`;
  };

  function reverseExcelName(cellName) {
    const match = cellName.match(/^([A-Z]+)(\d+)$/);
    if (!match) {
      throw new Error("Invalid cell name");
    }

    const colLetters = match[1];
    const row = parseInt(match[2], 10) - 1; // Subtract 1 for zero-based index

    // Convert column letters to a number, starting from 0
    let col = 0;
    for (let i = 0; i < colLetters.length; i++) {
      col = col * 26 + (colLetters.charCodeAt(i) - 65);
    }
    return { col, row };
  }

  const addComment = async (note) => {
    try {
      const promise = instance.post(`/api/v1/client/refund/add/comment/${client_id}`, {
        year: selectedYear,
        month: selectedMonth,
        comment: note,
        cell_no: getExcelCellName({
          row: modalInfo.cell.row,
          col: modalInfo.cell.col,
        }),
        status: 4,
      });

      const res = await toast.promise(promise, {
        loading: "Saving...",
        success: <b>Comment saved!</b>,
        error: <b>Could not save.</b>,
      });
      const noteArr = [...modalInfo.cell.noteArr, res.data.data];
      updateNote(note, { row:modalInfo.cell.row, col:modalInfo.cell.col }, noteArr);
    } catch (error) {
      console.error("error", error);
    }
  };

  const fetchAndProcessFile = async () => {
    try {
      //setLoading(true);
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      setData(rows);
      setRefresh((ref) => !ref);
    } catch (error) {
      console.error("Failed to fetch and process the file", error);
    } finally {
      //setLoading(false);
    }
  };

  const handleCellRightClick = (rowIndex, colIndex, e) => {
    e.preventDefault();

    const cellData = data[rowIndex][colIndex];
    const cell = {
      row: rowIndex,
      col: colIndex,
      note: cellData?.note || "",
      noteArr: cellData?.noteArr || []
    };

    setModalInfo({ ...modalInfo, isVisible: true, cell });
  };

  const saveNote = (note, rowColObj) => {
    if (note) {
      addComment(note);
      console.log("1 data", data);
      const updatedData = [...data];
      const { row, col } = rowColObj ? rowColObj : modalInfo.cell;

      if (updatedData[row]) {
        if (!updatedData[row][col]?.note) {
          updatedData[row][col] = { value: updatedData[row][col], note };
        } else if (updatedData[row][col]) {
          updatedData[row][col].note = note;
        } else {
          // Handle the case where col doesn't exist in the row
          // updatedData[row][col] = { value: "", note }; // or handle as needed
        }
      } else {
        // Handle the case where the row doesn't exist
        updatedData[row] = [];
        updatedData[row][col] = { value: "", note }; // or handle as needed
      }
      setData(updatedData);
      setModalInfo({ isVisible: false, cell: null, note: "" });
    }
  };

  const updateNote = (note, rowColObj, noteArr,) => {
    if (note) {
      //addComment(note);
      //console.log('1 data', data);
      const updatedData = [...data];
      const { row, col } = rowColObj ? rowColObj : modalInfo.cell;

      if (updatedData[row]) {
        if (!updatedData[row][col]?.note) {
          updatedData[row][col] = { value: updatedData[row][col], note, noteArr };
        } else if (updatedData[row][col]) {
          updatedData[row][col].note = note;
          updatedData[row][col].noteArr = noteArr;
        } else {
          // Handle the case where col doesn't exist in the row
          // updatedData[row][col] = { value: "", note }; // or handle as needed
        }
      } else {
        // Handle the case where the row doesn't exist
        updatedData[row] = [];
        updatedData[row][col] = { value: '', note, noteArr }; // or handle as needed
      }
      //row == 10 && console.log('**10 data 1', updatedData[row][col]);
      //console.log('**updated', updatedData);
      setData(updatedData);
      setModalInfo({ ...modalInfo, isVisible: false, cell: null, note: '', noteArr });
    }
  };

  const isCellHighlighted = (rowIndex, colIndex) => {
    const cellName = getExcelCellName({ row: rowIndex, col: colIndex });
    return allCells?.some((cell) => cell.cell_no === cellName);
  };

  useEffect(() => {
    const validCells = allCells.filter((cell) => cell.cell_no !== "None");
    validCells.forEach((cell) => {
      const { row, col } = reverseExcelName(cell.cell_no);
      const note = cell.comments?.[0].comment;
      const noteArr = cell.comments;
      if (note) {
        updateNote(note, { row, col }, noteArr);
      }
    });
  }, [allCells, refresh]);

  useEffect(() => {
    if (fileUrl) {
      fetchAndProcessFile();
    }
  }, [fileUrl]);

  useEffect(() => {
    if (reloadSheet) getSheet();
  }, [selectedMonth, selectedYear, reloadSheet]);

  return (
    <>
      {loading ? (
        <p className="text-center my-4">...loading</p>
      ) : (
        <div className="">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th></th>
                  {data[0] &&
                    ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"].map((_, index) => (
                      <th key={index}>{String.fromCharCode(65 + index)}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) =>
                  rowIndex >= 8 ? (
                    <tr key={rowIndex}>
                      <th>{rowIndex + 1}</th>
                      {row.length === 1 ? (
                        <td
                          className="bg-[#eff2f3] text-[#1e4466]"
                          colSpan={12}
                        >
                          {typeof row[0] === 'object' && row[0] !== null
                              ? row[0]?.value !== undefined && row[0]?.value !== null
                                ? row[0]?.value
                                : ''
                              : row[0] !== undefined && row[0] !== null
                              ? row[0]
                              : ''}
                        </td>
                      ) : (
                        Array.from({ length: 12 }).map((_, colIndex) => (
                          <td
                            className={`${rowIndex === 8 ? "bg-[#0d4473] text-white whitespace-nowrap" : ""}`}
                            id={getExcelCellName({
                              row: rowIndex,
                              col: colIndex,
                            })}
                            key={colIndex}
                            onContextMenu={(e) => handleCellRightClick(rowIndex, colIndex, e)}
                            style={{
                              backgroundColor: isCellHighlighted(rowIndex, colIndex) ? "#FFFFCC" : row[colIndex]?.note ? "#FFFFCC" : "",
                            }}
                          >
                           {typeof row[colIndex] === 'object' && row[colIndex] !== null
                              ? row[colIndex]?.value !== undefined && row[colIndex]?.value !== null
                                ? row[colIndex]?.value
                                : ''
                              : row[colIndex] !== undefined && row[colIndex] !== null
                              ? row[colIndex]
                              : ''}
                          </td>
                        ))
                      )}
                    </tr>
                  ) : (
                    <tr key={rowIndex}>
                      <td>{rowIndex + 1}</td>
                      {row.length === 1 ? (
                        <td
                          className="bg-[#eff2f3] text-[#1e4466]"
                          colSpan={12}
                        >
                          {typeof row[0] === 'object' && row[0] !== null
                              ? row[0]?.value !== undefined && row[0]?.value !== null
                                ? row[0]?.value
                                : ''
                              : row[0] !== undefined && row[0] !== null
                              ? row[0]
                              : ''}
                        </td>
                      ) : (
                        Array.from({ length: 12 }).map((_, colIndex) => (
                          <td
                            key={colIndex}
                            id={getExcelCellName({
                              row: rowIndex,
                              col: colIndex,
                            })}
                            onContextMenu={(e) => handleCellRightClick(rowIndex, colIndex, e)}
                            style={{
                              backgroundColor: isCellHighlighted(rowIndex, colIndex) ? "#FFFFCC" : row[colIndex]?.note ? "#FFFFCC" : "",
                            }}
                          >
                            {typeof row[colIndex] === 'object' && row[colIndex] !== null
                              ? row[colIndex]?.value !== undefined && row[colIndex]?.value !== null
                                ? row[colIndex]?.value
                                : ''
                              : row[colIndex] !== undefined && row[colIndex] !== null
                              ? row[colIndex]
                              : ''}
                          </td>
                        ))
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          {modalInfo.isVisible && (
            <NoteModal
              noteArr={modalInfo.cell.noteArr}
              note={modalInfo.cell.note}
              onSave={saveNote}
              status={status}
              setStatus={setStatus}
              onClose={() => setModalInfo({ isVisible: false, cell: null, note: "" })}
            />
          )}
        </div>
      )}
    </>
  );
}

export default XlsxTableRefund;
