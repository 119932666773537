import { Container, Card, Row, Col} from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import Base from "../Base";

export default function UnAuthorized() {
    return (<Base><Container>
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Card.Title><i class="ri-spam-2-fill" style={{fontSize: 40, color: '#0F4372'}}></i></Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <h3>Authorization Error</h3>
                        <h4>You do not have enough permission to access that page.  <NavLink style={{color: '#0F4372'}} to="/"> Go to Dashboard</NavLink></h4>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container></Base>)
}