import { 
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    Button,
    Form,
    CardBody,
    Table,
    FormCheck, 
    Alert,
    CardFooter,
    Breadcrumb, 
    InputGroup
  } from "react-bootstrap";
  import { useEffect, useState, useRef } from "react";
  import { useSelector, useDispatch } from "react-redux";
  
import { NavLink, useNavigate } from "react-router-dom";
import { getUsers, updateStatus, resetStore } from "../../slices/userReducer";
import { CURRENT_PAGE, PER_PAGE } from "../../constants/user";
import PaginationComponent from "../common/Pagination";
import useCheckAuthorization from "../../hooks/checkAuthorization";
import { PAGE_PERMISSIONS, CRUD_PERMISSIONS, RESTRICT_ASSIGN_ROLES } from "../../constants/user";

export default function UserList() {
    const [current_page, setCurrentPage] = useState(CURRENT_PAGE)
    const searchRef = useRef()

    const { 
        data,
        error,
        success,
        successMsg,
        total_count,
    } = useSelector((state) => state.user)
    const dispatch = useDispatch();

    const navigate = useNavigate();
  
  
    const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.user)
    const addPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.add)
    const disablePermission =  useCheckAuthorization(CRUD_PERMISSIONS.user.delete)
    const editPermission= useCheckAuthorization(CRUD_PERMISSIONS.user.edit)
    const assignPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.assign)
  
    useEffect(() => {
  
      if (!hasPermission) {
        navigate('/authorization-error');
      }
    }, [hasPermission])  


    useEffect(() => {
        dispatch(getUsers({page: current_page}))
    },[])

    const switchToggle = (id, e) => {
        dispatch(updateStatus({id, active:  e.target.checked, page: current_page}))
    }

    const handlePagination = (number) => {
        setCurrentPage(number)
        dispatch(getUsers({page: number}))
    }

    const handleSearch = () => {
      const searchText = searchRef.current.value.trim();
      if (searchText) {
        dispatch(getUsers({page: current_page, search: searchText}))
      } else {
        dispatch(getUsers({page: current_page}))
      }
    }

    return (<Container>
      <Row>
        <Col
          className="mr-auto text-center"
        >
          <Breadcrumb>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/'}}>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>
              Users
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row> 
    {(success || error ) && (<Row className="justify-content-md-center">
        <Col md={3}>
        <Alert 
        dismissible
        variant={success ? 'success' : 'danger'}
        onClose={() => dispatch(resetStore())}
        >
        <p>
        {success ? successMsg : error}
        </p>
    </Alert></Col></Row>)}    
    <Row>
      <Col sm={12}>
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <Col
              sm={3}
              md={3}
            >
              <div class="header-title">
                <Card.Title>User List</Card.Title>
              </div>
            </Col>
            <Col
            sm={3}
            md={3}
            className="text-right"
            >
              {addPermission && <NavLink to="add-user"><Button variant="primary" >
                <span>+</span> Add New
              </Button></NavLink>}
            </Col>
            <Col
              sm={6}
              md={6}
              className="text-right"
            >
               <div id="user_list_datatable_info" class="dataTables_filter">
                  <Form.Group
                    className="form-group mb-0"
                  >
                    <InputGroup>
                      <Form.Control
                        placeholder="Search"
                        ref={searchRef}
                      />
                      <Button variant="primary" onClick={handleSearch} ><i className="ri-search-line"></i></Button>
                    </InputGroup>
                  </Form.Group>
               </div>
            </Col>

          </CardHeader>
          <CardBody>
            <Col>
            {!!data.length && <Table responsive bordered striped id="user-list-table" className="mt-4">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(user => {
                  console.log(user)
                    return (<tr key={user.id}>
                    <td >{user.name}</td>
                    <td>{user.phone}</td>
                    <td>{user.email}</td>
                    <td>{user.role.name}</td>
                        <td className="align-middle"><FormCheck
                            inline
                            id="userStatus"
                            type="switch"
                            defaultChecked={user.active}
                            onChange={(e) => switchToggle(user.id,e)}
                            disabled={!disablePermission}
                        /></td>
                        <td>
                          <Row>
                           <Col md={2}>
                           {editPermission &&<NavLink to={`edit-user/${user.id}`}><Button
                            variant="link"
                            className="dropdown-item"
                            title="Edit User"
                        >
                            <i className="ri-pencil-fill mr-2"></i>
                        </Button></NavLink>
                        }
                           </Col> 
                         {!RESTRICT_ASSIGN_ROLES.includes(user.role.id) && assignPermission && <Col md={2}>
                           <NavLink to={`assign-client/${user.id}`}><Button
                            variant="link"
                            className="dropdown-item"
                            title="Assign Clients"
                        >
                            <i className="ri-links-line mr-2"></i>
                        </Button></NavLink>
                           </Col> } 
                          </Row>            
                       

                       

                        </td>
                  </tr>)
                })}

              </tbody>
            </Table>
            }
            {
              !data.length && 'No Users Found'
            
            }
            </Col>
          </CardBody>
          <CardFooter>
            <Row >
                <Col 
                sm={12}
                md={12}
                lg={12}
                className="d-flex justify-content-end">
                <PaginationComponent
                    total_count={total_count}
                    current_page={current_page} 
                    handleOnClick={handlePagination}
                    per_page={PER_PAGE}
                />  
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  </Container>) 
}