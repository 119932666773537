import { Fragment, useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal  from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { resetUploadDetails, getFiles } from '../../slices/fileUploadReducer';
import { useParams } from 'react-router-dom';

export default function Footer(props){
    const { sticky = true} = props 
    const [progress, setProgress] = useState(0) 
    const dispatch = useDispatch()
    const { folder_id } = useParams();
    const {
        total_upload_file_count, 
        total_files_uploaded,  
        upload_process_status, 
        current_page
    } = useSelector(state => state.files);

    useEffect(() => {
        const percentage = (total_files_uploaded/total_upload_file_count)*100
        setProgress(percentage)

    },[total_files_uploaded])

    return (
    <Fragment> 
    {upload_process_status == 1 && (<div className='d-flex justify-content-end fixed-bottom'>
        <Card style={{ width: '18rem', marginBottom: 73 }}>
        <Card.Body>
            <Card.Text>
            Uploaded {total_files_uploaded} File of {total_upload_file_count}
            </Card.Text>
            <ProgressBar variant={progress == 100 ? 'success': ''} now={progress} />
            
        </Card.Body>
        </Card>
    </div> )}         
    <footer className={`iq-footer ${sticky ? 'fixed-bottom': ''}`}>
        <div className="container-fluid">
           
        <div className="row">
            <div className="col-lg-6">
            <ul className="list-inline mb-0">
                <li className="list-inline-item">
                <a href="#">Privacy Policy</a>
                </li>
                <li className="list-inline-item">
                <a href="#">Terms of Use</a>
                </li>
            </ul>
            </div>
            <div className="col-lg-6 text-right">
            &copy; Copyright 2024 <a href="#">Preface Venture</a> All Rights
            Reserved.
            </div>
        </div>
        </div>
  </footer>
  </Fragment>  )
}