import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { FaDownload, FaExpand } from 'react-icons/fa6';
import { format } from 'date-fns'
import GSTR1SheetModal from './GSTR1SheetModal';
import RefundsSheetModal from './refund/RefundsSheetModal';

const ViewSheetSummary = ({sheetSummaryData, handleSheetSubmit, sheetType, getSheet}) => {

  const [showSheetModal, setShowSheetModal] = useState(false);
  const [viewOnlySheetData, setViewOnlySheetData] = useState(null);

  const handleSheetShow = (sheetData) => {
    if(sheetData){
      setViewOnlySheetData(sheetData);
    }else{
      setViewOnlySheetData(null);
    }
    setShowSheetModal(true);
  }
  const handleSheetClose = async () => {
    await getSheet();
    setShowSheetModal(false);
  }
  const resolvedComments = sheetSummaryData?.all_comments.filter(comment =>  comment.status === 4);
  return (
    <>
      <ul className="list-group list-group-flush r-list-group sheet-summary-list-group">
        {sheetSummaryData.sheet &&  
          <li class="list-group-item">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <i className="las la-file-excel pa-text-success mx-2"></i>
                {sheetSummaryData.sheet.file_name}
              </div>
              <div className="d-flex align-items-center">
                {/* View Button with Icon */}
                <a href="#" className="p-0 mx-2 text-sm d-flex align-items-center cursor-pointer" onClick={() => handleSheetShow()}>
                  View <FaExpand className="ml-1" /> {/* Icon aligned to the right of View text */}
                </a>
                <span className="mx-1">|</span>
                
                {/* Download Button with Icon */}
                <a href={sheetSummaryData.sheet.url} target="_blank" className="p-0 mx-2 text-sm d-flex align-items-center">
                  Download <FaDownload className="ml-1" /> {/* Icon aligned to the right of Download text */}
                </a>
                <span className="mx-1">|</span>
                
                {/* Approval Status */}
                {sheetSummaryData.sheet.status === 1 && <span className="text-sm">Approved</span>}
                {sheetSummaryData.sheet.status === 2 && <><span className="text-sm">Rejected</span> <span className="mx-1">|</span></>}
                {sheetSummaryData.sheet.status === 3 && <span className="text-sm">Waiting For Approval</span>}
                {sheetSummaryData.sheet.status === 0 || sheetSummaryData.sheet.status === 2 ? (
                  
                    <Button type="button" variant="primary" size="sm" onClick={() => handleSheetSubmit(4)}>Submit for Approval</Button>
                  
                ) : ''}
              </div>
            </div>
            <div className="d-flex align-items-left mt-2">
              {sheetSummaryData.sheet.updated_date && <>
                <small>{format(sheetSummaryData.sheet.updated_date, 'dd-MM-yyyy')}</small>
                <small className="mx-1">|</small>
              </>}
              <small>({sheetSummaryData.all_comments.length}) Comments</small>
              <small className="mx-1">|</small>
              <small>({resolvedComments.length}) Resolved</small>
            </div>
          </li>
        }
        {sheetSummaryData?.allSheet?.map((sheetData, i) => {
            return (
              <li class="list-group-item" key={i+1}>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <i className="las la-file-excel pa-text-success mx-2"></i>
                    {sheetData.file_name}
                  </div>
                  <div className="d-flex align-items-center">
                    {/* View Button with Icon */}
                    <a href="#" className="p-0 mx-2 text-sm d-flex align-items-center cursor-pointer" onClick={() => handleSheetShow(sheetData)}>
                      View <FaExpand className="ml-1" /> {/* Icon aligned to the right of View text */}
                    </a>
                    <span className="mx-1">|</span>
                    
                    {/* Download Button with Icon */}
                    <a href={sheetData.url} target="_blank" className="p-0 mx-2 text-sm d-flex align-items-center">
                      Download <FaDownload className="ml-1" /> {/* Icon aligned to the right of Download text */}
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-left mt-2">
                  {sheetData.updated_date && <small>{format(sheetData.updated_date, 'dd-MM-yyyy')}</small>}
                </div>
              </li>
            )
        })}
      </ul>
      {sheetType === 'gstr1' && <GSTR1SheetModal showSheetModal={showSheetModal} handleSheetClose={handleSheetClose} handleSheetSubmit={handleSheetSubmit} sheetSummaryData={sheetSummaryData} viewOnlySheetData={viewOnlySheetData}/>}
      {sheetType === 'refunds' && <RefundsSheetModal showSheetModal={showSheetModal} handleSheetClose={handleSheetClose} handleSheetSubmit={handleSheetSubmit} sheetSummaryData={sheetSummaryData} viewOnlySheetData={viewOnlySheetData}/>}
      
    </>
  )
}

export default ViewSheetSummary
