import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import filedownload from 'js-file-download'
import instance from '../services/baseService'
import { ROUTES } from '../constants/file-uploads'

export const uploadFile = createAsyncThunk('fileUpload/uploadFile', async ({folder_id, file, is_refund},thunkAPI) => {
    var formData = new FormData();
    formData.append("file", file);
    if(is_refund)
        formData.append("is_refund", is_refund);
    const response = await instance.post(
        ROUTES.UPLOAD_FILE(folder_id),
        formData,
        { 
            headers: {
                'Content-Type': 'multipart/form-data'
      
            }
        })
      
    // // thunkAPI.dispatch(getFiles(folder_id));    
    return response.data
})

export const updateEgm = createAsyncThunk('fileUpload/uploadEgm', async ({id, folder_id, data}) => {
    const response = await instance.post(
        ROUTES.EGM_UPDATE(id, folder_id),
        data,
        )
    // const thunkAPI.getState()    
    // thunkAPI.dispatch(getFiles(folder_id));    
    return response.data
})  


export const downloadExcel = createAsyncThunk('fileUpload/downloadExcel', async ({folder_id, is_refund}) => {
    const response = await instance.get(
        ROUTES.DOWNLOAD_EXCEL(folder_id, is_refund),
        {
            responseType: 'blob',
          }
        )
    // const thunkAPI.getState()    
    // thunkAPI.dispatch(getFiles(folder_id));    
    return  filedownload(response.data, 'download.xlsx')
}) 

export const downloadErrorLogs = createAsyncThunk('fileUpload/downloadError', async ({folder_id, is_refund}) => {
    const response = await instance.get(
        ROUTES.DOWNLOAD_ERROR_LOGS(folder_id, is_refund),
        {
            responseType: 'blob',
          }
        )
    // const thunkAPI.getState()    
    // thunkAPI.dispatch(getFiles(folder_id));    
    return  filedownload(response.data, 'download-errors.xlsx')
}) 

export const downloadHSNLogs = createAsyncThunk('fileUpload/downloadError', async ({folder_id, is_refund}) => {
    const response = await instance.get(
        ROUTES.DOWNLOAD_HSN_LOGS(folder_id, is_refund),
        {
            responseType: 'blob',
          }
        )
    // const thunkAPI.getState()    
    // thunkAPI.dispatch(getFiles(folder_id));    
    return  filedownload(response.data, 'download-hsn.xlsx')
}) 

export const getFiles = createAsyncThunk('fileUpload/getFiles', async ({folder_id, page, filter, q, is_refund}) => {
    filter = filter ? filter : 0;
    page = page ? page : 1;

    let data = {
        page,
        filter_type : filter
    }

    if (q) {
        data['q'] = q
    }
    if(is_refund)
        data['is_refund'] = is_refund

    const response = await instance.post(
        ROUTES.GET_FILES(folder_id),
        data
        )
    return response.data
})

export const deleteFile = createAsyncThunk('fileUpload/deleteFile', async ({id,folder_id},thunkAPI) => {
    const response = await instance.get(
        ROUTES.DELETE_FILE(id,folder_id)
        )  
    thunkAPI.dispatch(getFiles({folder_id}));    
    return response.data
})


export const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState: {
      data: [],
      loading: false,
      error: null,
      success: false,
      successMsg: null,
      total_count: 0,
      total_upload_file_count: 0,
      total_files_uploaded: 0,
      total_files_uploaded_failed: 0, 
      uploading: false,
      upload_process_status: 0,
      current_page: 1,
      show_eg_icon: false,
      eg_table_open: false,
      error_count: 0,
      processed_count: 0,
      show_tab: false, 
      total_file_count: 0
    },
    reducers: {
        setFileUploadCount(state, action){
            state.total_upload_file_count = action.payload
        },
       
        resetUploadDetails(state){
          state.total_upload_file_count = 0
          state.total_files_uploaded = 0
          state.total_files_uploaded_failed = 0
          state.upload_process_status = 0
        },
        setCurrentPage(state, action) {
            state.current_page = action.payload
        },
        showEgIcon(state, action) {
            state.show_eg_icon = action.payload
        },
        showEgTable(state, action) {
            state.eg_table_open = action.payload
        },
        resetStore(state, action){
           if (action.payload == 'all') {
            state.data = []
            state.loading = false
            state.error = null
            state.success = false
            state.successMsg = null
            state.total_count = 0
            state.total_upload_file_count = 0
            state.total_files_uploaded = 0
            state.total_files_uploaded_failed = 0
            state.uploading = false
            state.upload_process_status = 0
            state.current_page = 1
            state.show_eg_icon = false
            state.eg_table_open = false
            state.error_count = 0
            state.processed_count = 0
            state.show_tab = false
            state.total_file_count = 0
            }

            if (state.upload_process_status == 1) {
                state.upload_process_status = 0
            }
            
                state.error = null
                state.success = false
                state.successMsg = null
        },
        resetCountDetails(state){
            state.error_count = 0
            state.processed_count = 0
            state.show_tab = false
            state.total_file_count = 0 
        }
    },
    extraReducers: (builder) => {
      builder.addCase(uploadFile.pending, (state, action) => {
       
          state.loading = true
          state.uploading = true
          state.upload_process_status = 1
      })
      builder.addCase(uploadFile.fulfilled, (state, action) => {
          state.loading = false
          state.uploading = false
          state.total_files_uploaded += 1;
      })
      builder.addCase(uploadFile.rejected, (state, action) => {
          state.loading = false
          state.total_files_uploaded_failed += 1;
      })
      builder.addCase(getFiles.pending, (state, action) => {
          state.loading = true
      })
      builder.addCase(getFiles.fulfilled, (state, action) => {
          state.loading = false
          state.data = action.payload.data?.lists
          state.total_count = action.payload.data?.total_count
          state.error_count = action.payload.data?.error_count
          state.processed_count = action.payload.data?.total_processed
          state.show_tab = true
          state.total_file_count = action.payload.data?.error_count + action.payload.data?.total_processed
      })
      builder.addCase(getFiles.rejected, (state, action) => {
          state.loading = false
          state.error = 'Unable to fetch files'
      })
      builder.addCase(updateEgm.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(updateEgm.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.successMsg = 'Egm Updated successfully'
        
    })
    builder.addCase(updateEgm.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to update Egm'
    })
    builder.addCase(deleteFile.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(deleteFile.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.successMsg = 'Deleted file successfully'
        
    })
    builder.addCase(deleteFile.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to delete file'
    })

    builder.addCase(downloadHSNLogs.rejected, (state, action) => {
        state.loading = false
        state.success = true
        state.successMsg = 'Your request is being processed. Please wait a moment while we complete the task.'
    })
    },
  })

  export const {
    setFileUploadCount, 
    resetUploadDetails, 
    setCurrentPage, 
    showEgIcon, 
    showEgTable,
    resetStore,
    resetCountDetails,
} = fileUploadSlice.actions
  export default fileUploadSlice.reducer