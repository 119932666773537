import Base from "../Base"
import preface from  '../../images/preface.gif'
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import zip from '../../images/iceExtract.zip'

export default function Instruction() {
    const navigate = useNavigate()

    const handleDownload = () => navigate('/iceExtract.zip')

    return (<Base>
        <div class="row">
	<div class="col-md-2"></div>
	<div class="col-md-8">
   <h3>Install Chrome Extension in Developer Mode</h3>
   <p>Please see below the steps involved in installing a chrome extension in developer mode.</p>
   <img class="image w-100" src={preface}/>
   <h5>Step 1 - download zip file with the extension</h5>
   <p><Button><a href={zip} download="extension.zip">Download</a></Button></p>
   <p>If you already have downloaded a file with the plugin - skip this step.</p>
   <h5>Step 2 - extract the contents of the zip file</h5>
   <p>Right click on the downloaded zip file, then click "Extract Here".</p>
   <h5>Step 3 - open the extension page in google chrome</h5>
   <p>There are several ways todo that.</p>
   <p>Option 1: type <code class="inline">chrome://extensions</code> in the url bar and press enter.</p>
   <p>Option 2: click on the tree dots in the top right of the browser, then click "More tools" then click "Extensions".</p>
   <h5>Step 4 - activate developer mode</h5>
   <p>Turn on the switch on the top right of the page that says "Developer mode";</p>
   <h5>Step 5 - load unpacked extension</h5>
   <p>Click on the button on the top left of the page that says "Load unpacked".</p>
   <p>Then select a folder that contains the <code class="inline">manifest.json</code> file.</p>
   <p><b>OR</b> You can simply drag and drop the unzipped folder to extension page</p>
   <p><hr/></p>
   <p>That's it. You have installed the extension successfully.</p>
</div>
<div class="col-md-2"></div>
</div>
    </Base>)
}