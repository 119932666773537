import { string,object } from 'yup';
export const LOGIN_SCHEMA = object().shape({
    username: string().required("Username is required"),
    password: string().required("Password is required"),
})

export const ROUTES = {
    SIGN_IN: '/api/v1/login',
    REFRESH_TOKEN: 'api/v1/refresh',
    SIGN_OUT: '/api/v1/logout',
    GOOGLE_SIGN_IN: '/api/v1/google/login'
}