import Table from 'react-bootstrap/Table';
import { useEffect, useState, useRef } from 'react';
import playIcon from '../../images/play.png'
import { Fragment } from 'react';
import _ from 'lodash';

export default function EgmTable(props) {

    const { headers, data, handleSubmitFunc } = props
    const [tableData,setTableData]  = useState({});

    const stateData = useRef(tableData)

    const setRefData = data => {
      stateData.current = data
      setTableData(data)
    }

    useEffect (() => {
     const obj = _.keyBy(data, 'id')
     setRefData(obj)
     document.addEventListener('egmUpdateTrigger', (e) => handleEvent(e, obj) )
     return () => document.removeEventListener('egmUpdateTrigger',(e) => handleEvent(e, obj) )
    }, [data])

    const handleEvent = (e, objData) => {
      
      const { detail } = e;
      const {ibill, icode, idate} =  detail
      let obj = stateData.current[ibill];
      obj = {...obj, egm_number: icode, egm_date: idate};
      const data = {...stateData.current, [ibill]: obj}
      setRefData(data);
      handleSubmitFunc(obj);
    }

    const handleUpdate = (id, key, value) => {
      let obj = {...tableData};
      obj[id] = {...obj[id],[key]: value};
      setRefData(obj);
    }  

    const handleSubmit = (id) => {
      if (typeof handleSubmitFunc === 'function') {
        handleSubmitFunc(tableData[id]);
      }
    }
   
    return (
      <div className='mb-2' style={{maxHeight: '60vh', overflowY: 'scroll'}}>
        <Table 
          striped 
          bordered
          responsive
          hover
          size="sm"
          className='v-height v-egm'>
             <thead>
                <tr>
                 <th></th> 
                {Array.isArray(headers) && headers.map(header => {
                        return <th scope="col">{header.name}</th>
                })}
                </tr>
              </thead>
              <tbody>
              {Array.isArray(Object.values(tableData)) && Object.values(tableData).map((ind_data) => {

                return (
                    <tr> 
                      <td  ><img onClick={() => handleSubmit(ind_data.id)} className='w-50' id={`save_${ind_data.id}`} src={playIcon}/></td>
                    {Array.isArray(headers) && headers.map(header => {
                          const tdprops = {}
                          const inputprops = {}

                            if(!header.field) {
                              tdprops['id'] = `${header.key}${ind_data.id}`
                            } else {
                              inputprops['id'] = `${header.key}${ind_data.id}`
                            }

                        return ( <Fragment>
                                
                                <td {...tdprops} > {'invoice_number' == header.id  && (<input type="hidden" id={`id_${ind_data.id}`} value={ind_data.id} />)}
                                
                                     {!header.field && (
                                        `${(!!header.modifier ? header.modifier(ind_data[header.id]) : ind_data[header.id] )} ${(!!header.suffix ? header.suffix : '' )}`
                                     )}
                                     {
                                       header.field && ( <input  {...inputprops}  type="text" onChange={(e) => handleUpdate(ind_data.id, header.id, e.target.value)} value={ind_data[header.id] ? ind_data[header.id]: '' }/>) 
                                     }
                                </td>
                                </Fragment>
                                )
                    })}
                    </tr>
                 )})}    
              </tbody>  
        </Table>
        </div>
    )
}