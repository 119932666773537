import { Button } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import  {setScreenView } from '../../slices/commonReducer'


function GridView(props) {
    const { handleIconClick } = props;
    const dispatch = useDispatch()
    const {gridView} = useSelector(state => state.common)

    
    const handleClick = (val) =>
     { 
        dispatch(setScreenView(val))
     }

  return (
    <>
      <Button
        onClick={() => handleClick('list')}
        style={{ display: gridView == 'grid' ? 'inline-block' : 'none' }}
      >
        <i className="ri-layout-grid-line font-size-20"></i>
      </Button>
      <Button
        onClick={() => handleClick('grid')}
        style={{ display: gridView == 'list' ? 'inline-block' : 'none' }} >
        <i className="ri-list-check font-size-20"></i>
      </Button>
    </>
    )
}

export default GridView;