import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { ROUTES } from '../constants/common'
import instance from '../services/baseService'


export const getUserDetails = createAsyncThunk('common/getPermissions', async () => {
  const response = await instance.get(ROUTES.PROFILE)
  return response.data
})

export const getSearchResults = createAsyncThunk('common/getSearchResults', async (data, thunkAPI) => {
  console.log(data)
  try {
    const response = await instance.post(ROUTES.SEARCH, data)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }

})
const currentDate = new Date();
export const commonSlice = createSlice({
    name: 'common',
    initialState: {
      loading: false,
      gridView: 'grid',
      screen: 'dashboard',
      permissions: [],
      name: '',
      role: 'Staff',
      email: '',
      id: '',
      searchList: [],
      enableSearch: false,
      selectedMonth: currentDate.getMonth() + 1,
      selectedYear: currentDate.getFullYear(),
    },
    reducers: {
        setScreenView(state, action) {
            state.gridView = action.payload
        },
        setScreen(state, action) {
          state.screen = action.payload
        },
        setLoginInformation(state, action) {
          const { name, email, permissions, role, id } = action.payload
          state.name = name
          state.email = email
          state.permissions = permissions
          state.role = role
          state.id = id
        },
        resetSearch(state) {
          state.searchList = []
        },
        setSearch(state, action) {
          state.enableSearch = action.payload
        },
        setSelectedMonthYear: (state, action) => {
          state.selectedMonth = action.payload.month
          state.selectedYear = action.payload.year
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getUserDetails.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      const {name, permission_code, email, role,id} = action?.payload?.data
          state.name = name
          state.email = email
          state.permissions = permission_code
          state.role = role?.name
          state.id = id
    })
    builder.addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to fetch user details'
    })
    builder.addCase(getSearchResults.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getSearchResults.fulfilled, (state, action) => {
        state.searchList = action.payload.data.lists
        state.loading = false
    })
    builder.addCase(getSearchResults.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to find details'
    })
    },
  })

  export const { 
    setScreenView, 
    setScreen,
    setLoginInformation,
    openSearchModal,
    resetSearch,
    setSearch,
    setSelectedMonthYear
   } = commonSlice.actions;

  export default commonSlice.reducer