// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forex-container {
    margin: 8vh auto;
    width:50%;
    background-color: white;
    padding: 2rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
} 

.date-pick{
    display: flex;
    align-items: center;
    font-size: 1.4em;
    font-weight: bold;
    justify-content: space-around;
}

.add-button{
    width: 2.5em;
    height: 2.5em;
    margin-left: 2em;

}

.currency-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.currency-row .form-group {
    flex: 1 1;
    margin-right: 10px;
}

.currency-row .remove-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
}

.add-currency-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
}

.save-btn {
    background-color: #2196f3;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    width: 100%;
    margin-top: 20px;
}  `, "",{"version":3,"sources":["webpack://./src/components/Forex/Forex.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,SAAS;IACT,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,yCAAyC;IACzC,WAAW;IACX,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;;AAEpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,SAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".forex-container {\n    margin: 8vh auto;\n    width:50%;\n    background-color: white;\n    padding: 2rem;\n    border-radius: 14px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n    z-index: 30;\n    animation: slide-down 300ms ease-out forwards;\n} \n\n.date-pick{\n    display: flex;\n    align-items: center;\n    font-size: 1.4em;\n    font-weight: bold;\n    justify-content: space-around;\n}\n\n.add-button{\n    width: 2.5em;\n    height: 2.5em;\n    margin-left: 2em;\n\n}\n\n.currency-row {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.currency-row .form-group {\n    flex: 1;\n    margin-right: 10px;\n}\n\n.currency-row .remove-btn {\n    background-color: #f44336;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 3px;\n}\n\n.add-currency-btn {\n    background-color: #4caf50;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    border-radius: 5px;\n    margin-top: 10px;\n}\n\n.save-btn {\n    background-color: #2196f3;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    cursor: pointer;\n    border-radius: 5px;\n    display: block;\n    width: 100%;\n    margin-top: 20px;\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
