import {string,object,ref, number} from 'yup';

export const CURRENT_PAGE = 1;
export const PER_PAGE = 10;

export const ROUTES = {
    LIST_USER: `/api/v1/list/user`,
    LIST_SINGLE_USER: (id) => `/api/v1/view/user/${id}`,
    LIST_ROLES: `/api/v1/roles`,
    CREATE_USER: `api/v1/create/user`,
    EDIT_USER: (id) => `/api/v1/update/user/${id}`,
    UPDATE_STATUS: (id) => `/api/v1/change/user/status/${id}`,
    ASSIGNED_CLIENTS: (id) => `/api/v1/view/user/clients/${id}`,
    UNASSIGNED_CLIENTS: (id) => `/api/v1/client/unassigned/list/${id}`,
    ASSIGN_CLIENT: (id) => `/api/v1/assign/bulk/client/${id}`,
    REMOVE_CLIENT: (id) => `/api/v1/remove/user/client/${id}`
}

export const USER_SCHEMA =(isUpdate) => object().shape({
    name: string().required("Name is required"),
    mobile : number().transform((value) => Number.isNaN(value) ? null : value )
    .nullable().min(10),
    email: string().email().required('Email is required'),
    password: isUpdate? string() : string().required('Password is required'),
    r_password: isUpdate? string() : string()
    .oneOf([ref('password'), null], 'Passwords must match'),
    user_role: string()
})

export const ROLE_PERMISSIONS =  {
    can_staff_add : 'Add Staff',
    can_change_staff: 'Edd Staff',
    can_view_staff : 'View Staff',
    can_disable_staff : 'Activate/Deactivate Staff',
    can_assign_client : 'Assign Client',
    can_add_client:  'Add Client',
    can_change_client: 'Edit Client',
    can_delete_client: 'Delete Client',
    can_view_client: 'View CLient',
    can_create_period: 'Add Period',
    can_change_period: 'Edit Period',
    can_view_period: 'View Period',
    can_delete_period: 'Delete Period',
    can_upload_files: 'Upload files'
}

export const PAGE_PERMISSIONS =  {
    user: ['can_staff_add', 'can_change_staff', 'can_view_staff', 'can_disable_staff','can_assign_client'],
    client: ['can_add_client', 'can_change_client', 'can_delete_client', 'can_view_client'],
    period: ['can_create_period', 'can_change_period', 'can_view_period', 'can_delete_period']
}

export const CRUD_PERMISSIONS = {
    user: {
        add: ['can_staff_add', 'add_user'],
        edit: ['can_change_staff'],
        delete: ['can_disable_staff' ],
        assign: ['can_assign_client']
    },
    client: {
        add: ['can_add_client', ],
        edit: ['can_change_client'],
        delete: ['can_delete_client']
    },
    period: {
        add: ['can_create_period', ],
        edit: ['can_change_period'],
        delete: ['can_delete_period']
    },
    files:{
        upload_files: ['can_upload_files']
    }
}

export const RESTRICT_ASSIGN_ROLES = [2]