import { NavLink } from "react-router-dom";
export const BASE_URL = "https://apistaging.prefaceventure.technology/";
// export const BASE_URL = "http://127.0.0.1:8000/";
export const ROUTES = {
  PROFILE: `/api/v1/profile`,
  SEARCH: `api/v1/client/item/search`,
};

export const TABLE_HEADERS = {
  1: [
    { key: "client_name", label: "CLIENT NAME" },
    { key: "gstin", label: "GST NUMBER" },
    {
      key: "id",
      label: "#",
      modifier: (data, callback) => (
        <NavLink onClick={callback} to={`/periods/${data.id}`}>
          View
        </NavLink>
      ),
    },
  ],
  2: [
    { key: "client_name", label: "CLIENT NAME" },
    { key: "gstin", label: "GST NUMBER" },
    {
      key: "id",
      label: "#",
      modifier: (data, callback) => (
        <NavLink onClick={callback} to={`/periods/${data.id}`}>
          View
        </NavLink>
      ),
    },
  ],
  3: [
    { key: "client", subkey: "client_name", label: "CLIENT NAME" },
    { key: "file_name", label: "FILE_NAME" },
    {
      key: "folder",
      subkey: "id",
      label: "#",
      modifier: (data, callback) => (
        <NavLink
          onClick={callback}
          to={`/files/${data.folder.id}?file_name=${data.search_text}`}
        >
          View
        </NavLink>
      ),
    },
  ],
};
