import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PAGE_PERMISSIONS } from "../constants/user";
import { useParams } from "react-router-dom";
import Icon from "./common/Icon";
import gstr1Icon from "../images/gstr1.svg";
import RefundsIcon from "../images/refunds.svg";
import Base from "./Base";
import useCheckAuthorization from "../hooks/checkAuthorization";
import ChatPopup from "./common/ChatPopup";

function TaxForms() {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.common);
  const { client_id } = useParams();
  console.log("client_id", client_id);

  const gstr1Click = () => {
    navigate(`/gstr-1/${client_id}`);
  };

  const refundsClick = () => {
    navigate(`/refunds/${client_id}`);
  };

  return (
    <Base>
      <div className="flex gap-x-10 items-center">
        {useCheckAuthorization(PAGE_PERMISSIONS.user) && (
          <Icon
            className="v-icons"
            title="GSTR 1"
            imageUrl={gstr1Icon}
            onClickFunc={gstr1Click}
          />
        )}
        {useCheckAuthorization(PAGE_PERMISSIONS.user) && (
          <Icon
            className="v-icons"
            title="Refunds"
            imageUrl={RefundsIcon}
            onClickFunc={refundsClick}
          />
        )}
      </div>
      <ChatPopup client_id={client_id} chat_type="GENERAL" />
    </Base>
  );
}

export default TaxForms;
