import UserForm from "./UserForm"
import { useDispatch, useSelector } from "react-redux"
import { createUser, resetStore } from "../../slices/userReducer"
import { Alert, Row, Col, Container, Breadcrumb } from "react-bootstrap";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useCheckAuthorization from "../../hooks/checkAuthorization";
import { CRUD_PERMISSIONS } from "../../constants/user";


export default function AddUser(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {successMsg, success, error} = useSelector(state => state.user)

    const hasPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.add)

    useEffect(() => {
      if (!hasPermission ) {
        navigate('/authorization-error')
      }
    }, [])

    useEffect(() => {
        if (success) {

        }
    }, [success])
    const handleSubmit = (data) => {
        const {
            name,
            email,
            user_role: role,
            password,
            phone
        } = data    
        dispatch(createUser({name, email, role, password, phone}))        
    }

    return  (<Container>
        <Row>
          <Col
            className="mr-auto text-center"
          >
            <Breadcrumb>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/'}}  >Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/user-management'}}>
              Users
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Add User
            </Breadcrumb.Item>
          </Breadcrumb>
          </Col>
        </Row> {(success || error ) && (<Row className="justify-content-md-center">
        <Col md={3}>
        <Alert 
        dismissible
        variant={success ? 'success' : 'danger'}
        onClose={() => dispatch(resetStore())}
        >
        <p>
        {success ? successMsg : error}
        </p>
    </Alert></Col></Row>)} <UserForm submitFunc={handleSubmit}/></Container> )
}