import UserForm from "./UserForm"
import { useDispatch, useSelector } from "react-redux"
import { editUser, resetStore, getSingleUser } from "../../slices/userReducer"
import { Alert, Row, Col,Breadcrumb, Container } from "react-bootstrap";
import { useEffect } from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
 import useCheckAuthorization from "../../hooks/checkAuthorization";
 import { CRUD_PERMISSIONS } from "../../constants/user";


export default function EditUser(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {successMsg, success, error, user, roles} = useSelector(state => state.user)
    const { user_id } = useParams()
    
    const hasPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.edit)

    useEffect(() => {
        if (roles.length) {
            dispatch(getSingleUser(user_id))
        }
    }, [roles])

    useEffect(() => {
      if (!hasPermission ) {
        navigate('/authorization-error')
      }

    },[])

    
    const handleSubmit = (data) => {
        const {
            name,
            email,
            user_role: role,
            phone
        } = data    
        dispatch(editUser({data:{name, email, role,  phone}, id: user_id}))        
    }

    return  (<Container>
    <Row>
        <Col
          className="mr-auto text-center"
        >
          <Breadcrumb>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/'}}  >Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/user-management'}}>
              Users
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Edit User
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row> 
    {(success || error ) && (<Row className="justify-content-md-center">
        <Col md={3}>
        <Alert 
        dismissible
        variant={success ? 'success' : 'danger'}
        onClose={() => dispatch(resetStore())}
        >
        <p>
        {success ? successMsg : error}
        </p>
    </Alert></Col></Row>)} 
    <UserForm 
    isUpdate
    user={user}
    submitFunc={handleSubmit}/></Container> )
}