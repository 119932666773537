import React from "react";
import { Button, Row, Table } from "react-bootstrap";

const RefundList = ({ refundData, refundEditAction, handleRefundDelete }) => {
  
  return (
    refundData.length > 0 && (
      <Table
        striped
        bordered
        hover
        className="mt-2"
      >
        <thead>
          <tr>
            <th>ARN Number</th>
            <th>ARN Date</th>
            <th>Status</th>
            <th colSpan={2}>Action</th>
          </tr>
        </thead>
        <tbody>
          {refundData.map((data) => (
            <tr key={data.id}>
              <td>{data.arn_no}</td>
              <td>{data.arn_date}</td>
              <td>{data.status.name}</td>
              <td>{<Button onClick={() => refundEditAction(data)}>Edit</Button>}</td>
              <td>{<Button onClick={() => handleRefundDelete(data.id)}>Delete</Button>}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
};

export default RefundList;
