import { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRouter";
import Dashboard from "./components/Dashboard";
import Statement3 from "./components/Statement3";
import Folder from "./components/Folder";
import Login from "./components/Login";
import FileUpload from "./components/FileUpload";
import Instruction from "./components/files/Instruction";
import UserModule from "./components/User";
import UserList from "./components/user/UserList";
import AddUser from "./components/user/AddUser";
import EditUser from "./components/user/EditUser";
import UnAuthorized from "./components/common/UnAuthorized";
import AssignClient from "./components/user/AssignClient";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./App.css";
import Gstr1 from "./components/Gstr1";
import { Toaster } from "react-hot-toast";
import Refunds from "./components/refund/Refunds";
import Clients from "./components/Clients";
import TaxForms from "./components/TaxForms";
import Forex from "./components/Forex/Forex";

function App() {
  return (
    <Fragment>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
          <Toaster />
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<Dashboard />} path="/" />
              <Route element={<Statement3 />} path="/statement3" />
              <Route element={<Forex />} path="/forex" />
              <Route element={<Clients />} path="/clients" />
              <Route
                element={<TaxForms />}
                path="/clients/tax-forms/:client_id"
              />
              <Route element={<Gstr1 />} path="/gstr-1/:client_id" />
              <Route element={<Refunds />} path="/refunds/:client_id" />

              <Route element={<Folder />} path="/periods/:client_id" />
              <Route element={<FileUpload />} path="/files/:folder_id" />
              <Route element={<Instruction />} path="/instructions" />
              <Route element={<UserModule />} path="/user-management">
                <Route element={<UserList />} index />
                <Route element={<AddUser />} path="add-user" />
                <Route element={<EditUser />} path="edit-user/:user_id" />
                <Route
                  element={<AssignClient />}
                  path="assign-client/:user_id"
                />
              </Route>
              <Route element={<UnAuthorized />} path="/authorization-error" />
            </Route>
            <Route element={<Login />} path="/login" />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </Fragment>
  );
}

export default App;
