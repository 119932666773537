
import { Modal, Spinner, Row, Col, Container}  from 'react-bootstrap';


export default function Loader() {
    return (
        <Modal show fullscreen style={{opacity: 0.9}}>
            <Modal.Body className='d-flex align-items-center' >
                <Container >
                    <Row className='justify-content-center'>
                        <Col md="auto">
                            <Spinner />
                        </Col>

                    </Row>
                </Container>



            </Modal.Body>
        </Modal>
       
       )
}