import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { DatePicker } from "antd";
import { REFUND_SCHEMA } from "../../constants/refunds";
import instance from "../../services/baseService";
import dayjs from 'dayjs';

const RefundForm = ({ submitFunc, existingRefundItem, refundStatusOptions }) => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ arnNo: '', arnDate: '',arnStatus:'' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [datepickerValue, setDatepickerValue] = useState('');
  const [errors, setErrors] = useState({
    arnNo: "",
    arnDate: "",
    arnStatus: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData(() => {
      return {
        ...formData,
        [name]: value,
      };
    });
  };
  const handleDateChange = (date, dateString) => {
    setDatepickerValue(date);
    setFormData(() => {
      return {
        ...formData,
        arnDate: dateString,
      };
    });

  };
  useEffect(() => {
    if (Object.keys(existingRefundItem).length > 0) {
      const {arnNo, arnDate, arnStatus} = existingRefundItem;
      setDatepickerValue(dayjs(arnDate));
      setFormData({ arnNo: arnNo, arnDate: arnDate,arnStatus:arnStatus.id});
      setIsEditMode(true);
    } else {
      setFormData({ arnNo: '', arnDate: '',arnStatus:'' });
      setDatepickerValue('');
      setIsEditMode(false);
    }

  }, [existingRefundItem]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (typeof submitFunc === "function") {
      let values = {
        arnNo: "",
        arnDate: "",
        arnStatus: "",
      };

      if (e.target) {
        values = {
          arnNo: formData.arnNo,
          arnDate: formData.arnDate,
          arnStatus: formData.arnStatus,
        };
      }
      try {
        const data = await REFUND_SCHEMA().validate(values, { abortEarly: false });
        setValidated(true);
        setErrors({});
        submitFunc(data, existingRefundItem?.id);
      } catch (err) {
        const errData = {};
        err.inner.forEach((e) => {
          errData[e.path] = e.message;
        });
        setValidated(false);
        setErrors(errData);
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Label htmlFor="arnNo">ARN No:</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                id="validationArnNo"
                name="arnNo"
                value={formData.arnNo}
                onChange={handleChange}
                isInvalid={!!errors.arnNo}
                disabled = {isEditMode}
              />
              <Form.Control.Feedback type="invalid">{errors.arnNo}</Form.Control.Feedback>
            </InputGroup>
          </Col>
          <Col md={6}>
            <Form.Label htmlFor="arnDate">ARN Date:</Form.Label>
            <InputGroup hasValidation>
              <DatePicker 
                className="min-w-[10rem] min-h-[45px]"
                id="arnDate"
                name="arnDate"
                onChange={handleDateChange}
                onKeyDown={(e) => e.preventDefault()}
                isInvalid={!!errors.arnDate}
                value={datepickerValue}
                cellHeight="24"
              />
              <Form.Control.Feedback type="invalid">{errors.arnDate}</Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6}>
            <Form.Label htmlFor="arnStatus">Status:</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                id="validationArnStatus"
                name="arnStatus"
                isInvalid={!!errors.arnStatus}
                aria-label="Default select example"
                onChange={handleChange}
                value={formData.arnStatus}
              >
              <option value="">Select Status</option>
              {refundStatusOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.arnStatus}</Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={2}>
            <Button
              type="submit"
              variant="primary"
            >
              {isEditMode ? 'Update' : 'Add'} Refund
            </Button>
          </Col>
        </Row>
      </Form>
  );
};

export default RefundForm;
