import axios from 'axios';
import { BASE_URL } from '../constants/common';
import {ROUTES} from '../constants/login'
import { setLoginInformation } from '../slices/commonReducer';
import store from '../store'
import { getPermissionsFromRole } from '../utils';
import { googleLogout } from '@react-oauth/google';

const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
})

instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
  
      return config;
    },
  
    (error) => {
      return Promise.reject(error);
    }
  );

// refresh token interceptor

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const newToken = await refreshAccessToken();
        
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        
        return instance(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        throw refreshError;
      }
    }
    // If it's not a 401 error or the request has already been retried, just throw the error
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh')
  try {
    localStorage.removeItem('token')
    const response  = await instance.post(ROUTES.REFRESH_TOKEN, {refresh: refreshToken});
    const { token, refresh } = response.data.data;
    localStorage.setItem("token", token)
    localStorage.setItem("refresh", refresh)
    return token

  } catch (error) {
    console.log(error)
    throw error
  }

};





export const signIn = async (credentails) => {
    try{
    const response = await instance.post(ROUTES.SIGN_IN, credentails );
    const { token, refresh, data: user_data  } = response.data
    localStorage.setItem('token', token)
    localStorage.setItem('refresh', refresh)
    if (user_data) {
      const { name, email, id, role, permission_code } = user_data
      let data = { name, email , id , role: role.name, permissions: permission_code }
      store.dispatch(setLoginInformation(data))
    }
    
    return {status: true }
    } catch(err) {
      const msg = err?.response?.data?.message || 'Invalid Username or Password'
      return {status:false, message: msg };
    }
}

export const googleLogin = async (credentails) => {
  try{
  const response = await instance.post(ROUTES.GOOGLE_SIGN_IN, credentails );
  const { token, refresh, data: user_data  } = response.data
  if (token) {
    localStorage.setItem('token', token)
    localStorage.setItem('refresh', refresh)
    if (user_data) {
      const { name, email, id, role, permission_code } = user_data
      let data = { name, email , id , role: role.name, permissions: permission_code }
      store.dispatch(setLoginInformation(data))
    }
  }  
  
  return {status: true }
  } catch(err) {
      const msg = err?.response?.data?.message || 'User verification pending'
      return {status:false, message: msg };
  }
}

export const signOut = async () => {
  try{
    const refresh = localStorage.getItem('refresh')
    await instance.post(ROUTES.SIGN_OUT , {refresh});
    localStorage.removeItem('token')
    localStorage.removeItem('refresh')
    googleLogout()
    return true
    } catch(err) {
        return false;
    }
}

export default instance