
import Base from "./Base";

import { Outlet } from "react-router-dom";

export default function UserModule () {

  return (
  <Base sticky_footer={false}>
    <Outlet/>
  </Base>)
}