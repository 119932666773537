import { Table, Button } from "react-bootstrap";
export default function ListView(props){

    const { 
        headers,
        data,
        handleViewFunc,
        handleDeleteFunc,
        handleEditFunc,
        showView = true,
        showDelete = true,
        showEdit = true,
        disableEditFunc,
        disableViewFunc,
        disableDeleteFunc
     } = props;

    

    const handleView = (id) => {
        if (typeof handleViewFunc === 'function') {
            handleViewFunc(id)
        } 
    }

    const handleDelete = (id) => {
        if (typeof handleDeleteFunc === 'function') {
            handleDeleteFunc(id)
        }
    }
    const handleEdit = (id) => {
        if (typeof handleEditFunc === 'function') {
            handleEditFunc(id)
        }
    }

    return (
        
            <>    
                      {Array.isArray(data) && data.length > 0 && ( <Table
                            striped 
                            bordered 
                            hover
                            responsive
                           
                        >
                            <thead>
                                <tr>
                                    {headers.map(header => {
                                        return <th scope="col" key={header.id}>{header.name}</th>
                                    })}
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>                                
                                   {Array.isArray(data) && data.map((ind_data) => {
                                    return (<tr key={ind_data.id}> 
                                        {Array.isArray(headers) && headers.map(header => {
                                        return ( <td key={header.id}>
                                            {`${(!!header.modifier ? header.modifier(ind_data[header.id]) : ind_data[header.id] )} ${(!!header.suffix ? header.suffix : '' )}`}
                                            </td>
                                            )

                                     })}
                                     <td>
                                        <div
                                        className="row"
                                        >
                                        
                                        {showView &&   <div className="col-md-2"> <Button
                                         onClick={() => handleView(ind_data?.id)}
                                         disabled={ typeof disableViewFunc === 'function' ? disableViewFunc(ind_data) :  false }
                                         variant="link"
                                          className="dropdown-item" 
                                          title="View"
                                        >
                                            <i className="ri-eye-fill mr-2"></i>
                                        </Button> </div> }
                                       
                                        
                                        {showDelete && <div className="col-md-2"><Button
                                           onClick={() => handleDelete(ind_data?.id)}
                                           variant="link"
                                           disabled={typeof disableDeleteFunc === 'function' ? disableDeleteFunc(ind_data) : false}
                                           className="dropdown-item"
                                           title="Delete"
                                           
                                            >
                                                <i className="ri-delete-bin-6-fill mr-2">
                                                </i>
                                        </Button></div>}
                                        
                                        
                                        {showEdit && <div className="col-md-2"><Button
                                          variant="link"
                                          className="dropdown-item"
                                          disabled={typeof disableEditFunc === 'function' ? disableEditFunc(ind_data) : false}
                                          onClick={() => handleEdit(ind_data?.id)}
                                          title="Edit"
                                            >
                                         <i className="ri-pencil-fill mr-2"></i>
                                        </Button></div>}
                                        
                                        </div>
                                    
                                    </td>
                                     </tr>)
                                   })}
                                   
                                {!Array.isArray(data) && (<tr><td> No Data Found </td></tr>) }
                             </tbody>    
                            </Table>)}
                            </> 
                            
    )
}