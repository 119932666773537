import Base from "./Base"
import ViewSelector from "./common/GridIconGenerator"
import Icon from "./common/Icon"
import PdfType from "./common/PdfType"
import { 
    Alert,
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Col
 } from "react-bootstrap"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {useDropzone,} from 'react-dropzone'
import { useSelector, useDispatch } from "react-redux"
import { 
    uploadFile,
    setFileUploadCount,
    getFiles,
    setCurrentPage,
    resetUploadDetails,
    showEgIcon,
    showEgTable,
    updateEgm,
    resetStore,
    resetCountDetails,
    deleteFile
 } from "../slices/fileUploadReducer"

import { useParams, useNavigate, NavLink, useSearchParams } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import ListView from "./common/ListView"
import { TABLE_HEADERS, EGM_TABLE_HEADERS, successStatusIcons } from "../constants/file-uploads"
import PaginationComponent from "./common/Pagination"
import { Modal, Button } from "react-bootstrap"
import DocViewer, {PDFRenderer} from 'react-doc-viewer'
import EgmTable from "./files/EgmTable"
import { setScreenView } from "../slices/commonReducer"
import useCheckAuthorization from "../hooks/checkAuthorization"
import { CRUD_PERMISSIONS } from "../constants/user"
import folderImage from '../images/folder.png'



export default function FileUpload() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [filesData, setFiles] = useState([])
    const [filter, setFilter] =  useState(0)
    const [showPdf, setShowPdf] = useState(false)
    const [pdfUrl, setPdfUrl] = useState(null)
    const {
        data, 
        uploading,
        total_files_uploaded,
        total_files_uploaded_failed,
        total_upload_file_count,
        upload_process_status,
        total_count,
        current_page,
        eg_table_open,
        success, 
        error,
        successMsg
     } = useSelector(state => state.files);
     const {gridView} = useSelector(state => state.common)

     const uploadPermission  = useCheckAuthorization(CRUD_PERMISSIONS.files.upload_files)

   

    const { folder_id} = useParams();

    useEffect(() => {
        const data = {
            folder_id,
            page: current_page
        }

        if (searchParams.get('file_name')){
            data['q'] = searchParams.get('file_name')
        }

        dispatch(getFiles(data))
        dispatch(showEgIcon(true))
        return () => {
            dispatch(showEgIcon(false))
            dispatch(resetUploadDetails());
            dispatch(resetCountDetails())
            dispatch(resetStore('all'))
            dispatch(setScreenView('grid'))
        }
    }, [])

    useEffect(() => {
       if(eg_table_open) { 
        dispatch(getFiles({folder_id,page: current_page, filter: 1}))
        dispatch(setScreenView(eg_table_open ? 'egm': 'grid'))
       } 
    }, [eg_table_open])

    useEffect(() => {
        if (upload_process_status == 1) {
            dispatch(getFiles({folder_id,page: current_page}))
            if(total_upload_file_count === total_files_uploaded)
                dispatch(resetUploadDetails())
        }

    },[upload_process_status, total_files_uploaded ])

    const handleFile = (files) => {
        dispatch(setFileUploadCount(files.length)); 
        files.forEach((file, index) => {
            setTimeout(() => dispatch(uploadFile({ folder_id, file })), 1000* (index + 1))
        });   
    }

   
    const handlePagination = (number) => {
        dispatch(setCurrentPage(number))
        const filter_type = gridView === 'egm' ? 1: 0
        dispatch(getFiles({folder_id,page: number, filter: filter_type}));
    }
 
    const {
        getRootProps, 
        getInputProps,
        open, 
        acceptedFiles
    } = useDropzone({
        noClick: true, 
        noKeyboard: true,
        noDrag: !uploadPermission,
        noDragEventsBubbling: true, 
        onDropAccepted: (files) => handleFile(files)
    })

    const handleFilterChange = (val) => {
        setFilter(val)
        dispatch(getFiles({folder_id,page: current_page, filter: val}));
    }


    const handlePdfClick = (url) => {
        const pdf = [{uri: url, fileType: 'pdf' }];
        setPdfUrl(pdf)
        setShowPdf(true)

    }

    const handleClose = () => {
        setShowPdf(false)
    }

    const handleDelete = (id, folder) => {
        dispatch(deleteFile({id, folder_id: folder}))
    }

    const handleEgmSubmit = (data) => {
        const { id, folder, egm_number, egm_date } = data;
        if (id && folder && egm_number && egm_date)
        dispatch(updateEgm({id, folder_id: folder, data: {egm_number, egm_date}}))
    }

    const handleCloseEgmTable = () => {
        dispatch(showEgTable(false))
        dispatch(getFiles({folder_id,page: current_page, filter: 0}))
        dispatch(setScreenView('grid'))
       
    }

    const handleBackToFolders = () => {
        dispatch(setScreenView('grid'))
        navigate(-1)
       
    }

    const handleSummaryClose = () => {
                dispatch(resetUploadDetails());
                dispatch(getFiles({folder_id, page: current_page}))
    }

    return (<BlockUi tag="div" className="pv-blackdrop" blocking={upload_process_status == 1} message="Uploading File! Please wait. Do not refresh or press back button"><Base sticky_footer={false} hideDatepicker={true}>
       
            <Row className="mb-4">
                <Col
                    md={4}
                    sm={4}
                    lg={4}
                    className="mr-auto text-center"
                >
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/'}}>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/statement3'}}>
                            Statement3
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={handleBackToFolders}>
                            Clients
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Files</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col
                   md={2}
                   sm={4}
                   lg={2}
                   className="ml-auto text-right" 
                >
                {uploadPermission &&gridView !== 'egm' && <Button variant="primary" className="mr-2" onClick={open}>
                        <i className="ri-file-upload-line font-size-20"></i>
                    </Button> }
                </Col>
                <Col
                    md={3}
                    sm={4}
                    lg={2}
                >   
                    {gridView === 'grid' && (
                        <div className="border border-2 btn-group btn-group-toggle" data-toggle="buttons">
                            <label className={`btn ${filter == 0 ? 'btn-secondary' : ''}`}>
                                <input type="radio"   onClick={() => handleFilterChange(0)} /> All
                            </label>
                            <label className={`btn ${filter == 1 ? 'btn-secondary' : ''}`}>
                                <input type="radio"  onClick={() => handleFilterChange(1)} /> Processed
                            </label>
                            <label className={`btn ${filter == 2 ? 'btn-secondary' : ''}`}>
                                <input type="radio"   onClick={() => handleFilterChange(2)} /> Failed
                            </label>
                        </div>
                    )}
                </Col>
                <Col
                    md={2}
                    sm={1}
                    lg={2}
                    className="text-center"
                >   
                    <ViewSelector />
                    
                </Col>
            </Row>
            {(success || error) && (
                <Row>
                    <Col
                        md={12}
                        className="justify-content-center d-flex"
                    >
                        <Alert
                            dismissible
                            variant={success ? 'success' : 'danger'}
                            onClose={() => dispatch(resetStore())}
                        >
                            <p>
                                {success ? successMsg : error}
                            </p>
                        </Alert>

                    </Col>
                </Row>
            )}
             <div style={{ height: "100vh" }} {...getRootProps({className: 'dropzone'})}>
             <input {...getInputProps()} />
            <Row
                style={{ display: gridView == 'grid' ? '' : 'none' }}
                className="v-table"
            >
                {(!Array.isArray(data) || data.length == 0) && (<div>No Files Uploaded Yet</div>)}
                <Icon title="Refunds" className="col-lg-3 col-md-3 col-sm-3" imageUrl={folderImage} onClickFunc={() => navigate(`/files/refunds/${folder_id}`)}/>
                {Array.isArray(data) && data.map(file => {
                    const { file_name, status, url, folder } = file;
                    return (<PdfType
                        className="v-icons"
                        key={data.id}
                        data={file}
                        status={status}
                        url={url}
                        title={file_name}
                        handleClick={handlePdfClick}
                        handleDeleteFunc={(id) => handleDelete(id, folder)}
                        showView={false}
                        showDelete={true}
                        showEdit={false}
                    />)
                })}
            </Row>
            <Row
                style={{ display: gridView == 'list' ? '' : 'none' }}
                className="v-table"
            >
                <Col>
                    {(!Array.isArray(data) || data.length == 0) && (<div>No Files Uploaded Yet</div>)}
                    <ListView
                        headers={TABLE_HEADERS}
                        data={Array.isArray(data) ? data : []}
                        handleDeleteFunc={(id) => handleDelete(id, folder_id)}
                        showView={false}
                        showDelete={true}
                        showEdit={false}
                        disableDeleteFunc={(file) => successStatusIcons.includes(file.status)}
                    />
                </Col>
            </Row>
            
            <Row
                style={{ display: gridView == 'egm' ? '' : 'none' }}
                className="v-table"
            >
                <Row className="mb-2">
                    <Col
                        md={5}
                        lg={5}
                        id="extenionconnectegmid"
                    ></Col>
                    <Col
                        md={7}
                        lg={7}
                        className="d-flex justify-content-end"
                    >
                        <Button variant="primary" onClick={handleCloseEgmTable}>
                            Close Table
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <input type="hidden" name="nextbill" id="nextbill" value="" />
                </Row>
                {gridView == 'egm' && <Row>
                    <Col>{Array.isArray(data) && !!data.length && <EgmTable
                        headers={EGM_TABLE_HEADERS}
                        data={data}
                        handleSubmitFunc={handleEgmSubmit}
                    />
                    }
                    {((Array.isArray(data) && !data.length) || (!Array.isArray(data))) && 'No Processed Data available to Fetch Egm Data'

                    }
                    </Col>
                </Row>}
            </Row>
            <Row>
                <Col
                    md={12}
                    className="d-flex justify-content-center mt-2"
                >
                    <PaginationComponent
                        total_count={total_count}
                        current_page={current_page} handleOnClick={handlePagination} />
                </Col>
            </Row>
            </div>
            <Modal size="xl" show={showPdf}>
                <Modal.Header className="justify-content-end">
                    <Button  variant="link" onClick={handleClose}>
                        &#x2715;
                    </Button>
                </Modal.Header>
                <Modal.Body >
                        <DocViewer
                        className="doc-viewer"
                        documents={pdfUrl}
                        disableHeader
                        pluginRenderers={[PDFRenderer]}
                    />
                </Modal.Body>
            </Modal>
            <Modal  show={upload_process_status === 1 && total_upload_file_count == (total_files_uploaded + total_files_uploaded_failed)}>
                <Modal.Header>
                <Modal.Title>Upload Summary</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                       
                       <Row>
                        <Col md={6}>Successfull:</Col><Col md={6}>{total_files_uploaded}</Col>
                        <Col md={6}>Failed:</Col><Col md={6}>{total_files_uploaded_failed}</Col>
                       </Row>
                </Modal.Body>
                <Modal.Footer>
                <Button  onClick={handleSummaryClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </Base></BlockUi>)
}