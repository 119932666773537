import logo from "../../logo.png";
import moment from "moment"
import {
  Button,
  ListGroup,
  Overlay,
  Tooltip,
  Modal,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { signOut } from "../../services/baseService";
import { getSearchResults, resetSearch } from "../../slices/commonReducer";
import { TABLE_HEADERS } from "../../constants/common";
import s3Icon from "../../images/s3icon.png";
import { useSelector, useDispatch } from "react-redux";
import {
  showEgTable,
  downloadExcel,
  getFiles,
  downloadErrorLogs,
  downloadHSNLogs,
} from "../../slices/fileUploadReducer";
import { setScreenView, setSearch, setSelectedMonthYear } from "../../slices/commonReducer";
import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useYearMonth } from "../YearMonth";
import { DatePicker } from "antd";
import dayjs from 'dayjs';

function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const {selectedMonth:initialMonth, selectedYear:initialYear } = useSelector((state) => state.common);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month(initialMonth - 1));
  const [monthString, setMonthString] = useState(new Date().getMonth() + 1);
  
  const { selectedYear, setSelectedYear } =
    useYearMonth();
  const {
    show_eg_icon,
    error_count,
    processed_count,
    total_file_count,
    show_tab,
  } = useSelector((state) => state.files);

  const { title, hideDatepicker, headerType, showSearchIcon = false } = props;
  const { name, email, role } = useSelector((state) => state.common);

  const { gridView, searchList, enableSearch } = useSelector(
    (state) => state.common
  );

  const { folder_id } = useParams();

  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState(1);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const target = useRef(null);

  const handleBack = () => {
    dispatch(getFiles({ folder_id, page: 1 }));
    dispatch(setScreenView("grid"));
    dispatch(showEgTable(false));
  };

  const handleSignOut = () => {
    signOut().then((response) => {
      navigate("/login");
    });
  };

  const handleDownload = () => {
    setShow(false);
    let is_refund = undefined;
    if(headerType === 'refunds')
      is_refund = 1;
    dispatch(downloadExcel({ folder_id, is_refund }));
  };

  const handleErrorLogs = () => {
    setShow(false);
    let is_refund = undefined;
    if(headerType === 'refunds')
      is_refund = 1;
    dispatch(downloadErrorLogs({ folder_id, is_refund }));
  };

  const handleHSN = () => {
    setShow(false);
    let is_refund = undefined;
    if(headerType === 'refunds')
      is_refund = 1;
    dispatch(downloadHSNLogs({ folder_id, is_refund }));
  };

  const searchData = () => {
    const data = {
      filter_type: searchType,
      q: searchText,
    };

    dispatch(getSearchResults(data));
  };

  const clearSearch = () => {
    setSearchText("");
    setSearchType(1);
    dispatch(resetSearch());
    dispatch(setSearch(false));
    setShowSearchModal(false);
  };


  function submitDate() {
    const monthMap = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };
    const monthNumber = monthMap[monthString]; 
    const yearNumber = parseInt(selectedYear);
    dispatch(setSelectedMonthYear({ month: monthNumber, year: yearNumber }))
  }

  const handleMonthChange = (date, dateString) => {
    setSelectedMonth(date);
    setMonthString(dateString);
  };

  

  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="header-md col-md-3 d-flex align-items-center justify-content-between">
            <i className="ri-menu-line wrapper-menu"></i>
            <Link to="/" className="header-logo">
              <img
                src={logo}
                className="img-fluid rounded-normal light-logo"
                alt="logo"
              />
            </Link>
          </div>
          <div className={`col-md-3 col-sm-1 ${show_eg_icon ? 'd-flex' : ''}`}>
            <h4>{title}</h4>
            {gridView === "egm" && (
              <Button onClick={handleBack}>
                <span>&#8592; Exit to Files</span>
              </Button>
            )}
            {show_eg_icon && (
              <>
                <img
                  onClick={() => dispatch(showEgTable(true))}
                  className="w-25 pb-2 v-icons"
                  src={s3Icon}
                />
                <Button
                  variant="link"
                  style={{ textDecoration: "none" }}
                  ref={target}
                  onClick={() => setShow(!show)}
                >
                  <i className="ri-file-download-line font-size-40"></i>
                </Button>
                <Overlay target={target.current} show={show} placement="bottom">
                  {(props) => (
                    <Tooltip
                      className="download-overlay"
                      style={{ backgroundColor: "#fff" }}
                      {...props}
                    >
                      <Button
                        style={{ color: "#000", textDecoration: "none" }}
                        variant="link"
                        onClick={handleDownload}
                      >
                        <i className="ri-folder-add-line pr-3"></i>Download
                        Excel
                      </Button>
                      <Button
                        style={{ color: "#000", textDecoration: "none" }}
                        variant="link"
                        onClick={handleErrorLogs}
                      >
                        <i className="ri-folder-add-line pr-3"></i>Download
                        Error Logs
                      </Button>
                      <Button
                        style={{ color: "#000", textDecoration: "none" }}
                        variant="link"
                        onClick={handleHSN}
                      >
                        <i className="ri-folder-add-line pr-3"></i>Download HSN
                      </Button>
                    </Tooltip>
                  )}
                </Overlay>
              </>
            )}
          </div>

          <div className={`flex gap-x-10 ${hideDatepicker && 'hidden'}`}>
            <DatePicker
              className="min-w-[10rem]"
              defaultValue={moment('2024','YYYY')}
              disabled
              picker="year"
            />
            

            <DatePicker 
            picker="month"
            className="min-w-[10rem]"
            format={"MMMM"}
            onChange={handleMonthChange}
            value={selectedMonth }
            />
            <button onClick={submitDate} className="bg-[#0d4473] px-4 rounded-sm text-white">
              Submit
            </button>
          </div>

          <div className="col-md-6">
            {!!folder_id && (
              <div id="extnVerifier">
                This system missing its supporting software{" "}
                <Link to="/instructions">Download Now</Link>
              </div>
            )}
            {show_tab && (
              <>
                <ListGroup horizontal>
                  <ListGroup.Item>
                    {total_file_count} files processed
                  </ListGroup.Item>
                  <ListGroup.Item>{processed_count} processed</ListGroup.Item>
                  <ListGroup.Item>{error_count} error</ListGroup.Item>
                </ListGroup>
              </>
            )}
          </div>
          <div className="col-md-3 col-sm-1">
            <div
              className={`navbar-collapse  justify-content-end ${!showSearchIcon && 'collapse'}`}
            >
              <ul className="navbar-nav ms-auto navbar-list align-items-center">
                <li
                  className="nav-item nav-search dropdown"
                  onClick={() => setShowSearchModal(true)}
                >
                  <i className="ri-search-line"></i>
                </li>
              </ul>
            </div>
            <div
              className="collapse navbar-collapse  justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto navbar-list align-items-center">
                {/* <li className="nav-item nav-icon dropdown">
                                <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="ri-question-line"></i>
                                </a>
                                <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton01">
                                    <div className="card shadow-none m-0">
                                        <div className="card-body p-0 ">
                                            <div className="p-3">
                                                <a href="#" className="iq-sub-card pt-0"><i className="ri-questionnaire-line"></i>Help</a>
                                                <a href="#" className="iq-sub-card"><i className="ri-service-line"></i>Terms and Policy</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                <li
                  className="nav-item nav-icon nav-search dropdown"
                  onClick={() => setShowSearchModal(true)}
                >
                  <i className="ri-search-line"></i>
                </li>
                <li className="nav-item nav-icon dropdown">
                  <a
                    href="#"
                    className="search-toggle dropdown-toggle"
                    id="dropdownMenuButton02"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="ri-settings-3-line"></i>
                  </a>
                  <div
                    className="iq-sub-dropdown dropdown-menu"
                    aria-labelledby="dropdownMenuButton02"
                  >
                    <div className="card shadow-none m-0">
                      <div className="card-body p-0 ">
                        <div className="p-3">
                          <a href="#" className="iq-sub-card pt-0">
                            <i className="ri-settings-3-line"></i> Settings
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item nav-icon dropdown caption-content">
                  <a
                    href="#"
                    className="search-toggle dropdown-toggle"
                    id="dropdownMenuButton02"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="caption bg-primary line-height">
                      {!!name ? name[0] : ""}
                    </div>
                  </a>
                  <div
                    className="iq-sub-dropdown dropdown-menu"
                    aria-labelledby="dropdownMenuButton03"
                  >
                    <div className="card mb-0">
                      <div className="card-header d-flex justify-content-between align-items-center mb-0">
                        <div className="header-title">
                          <h6 className="card-title mb-0">Profile</h6>
                        </div>
                        <div className="close-data text-right badge badge-primary cursor-pointer ">
                          <i className="ri-close-fill"></i>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="profile-header">
                          <div className="cover-container text-center">
                            <div className="rounded-circle profile-icon bg-primary mx-auto d-block">
                              {!!name ? name[0] : ""}
                            </div>
                            <div className="profile-detail mt-3">
                              <h5>{name ? name : ""}</h5>
                              <p>{role}</p>
                            </div>
                            <Button
                              onClick={handleSignOut}
                              className="btn btn-primary"
                            >
                              Sign Out
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <Modal
        show={showSearchModal || enableSearch}
        onHide={clearSearch}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <h1>Search</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={5}>
              <Form.Control
                type="text"
                id="Search"
                placeholder="Search For"
                size="sm"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Col>
            <Col md={5}>
              <Form.Select
                size="sm"
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
                value={searchType}
              >
                <option value="1">Client Name</option>
                <option value="2">GST Number</option>
                <option value="3">Invoice Number</option>
              </Form.Select>
            </Col>
            <Col md={1}>
              <span>
                <Button
                  size="sm"
                  disabled={searchText == ""}
                  onClick={searchData}
                >
                  Search
                </Button>
              </span>
            </Col>
          </Row>
          {Array.isArray(searchList) && !!searchList.length && (
            <Row className="mt-2">
              <Col md={12}>
                <h3>Search Results</h3>
              </Col>
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {TABLE_HEADERS[searchType].map((header) => (
                        <th key={header.key}>{header.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {searchList.map((data) => {
                      return (
                        <tr>
                          {TABLE_HEADERS[searchType].map((header) => {
                            let field_key = !!header.subkey;
                            let updatedData = { ...data };
                            updatedData.search_text = searchText;

                            return (
                              <td key={`${data.id}-${header.key}`}>
                                {!!header.modifier
                                  ? header.modifier(updatedData, clearSearch)
                                  : field_key
                                  ? data[header.key][header.subkey]
                                  : data[header.key]}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          {Array.isArray(searchList) && !searchList.length && (
            <Row className="mt-2">
              <Col> No Results Found</Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default Header;
