import { useState, useRef, useEffect } from 'react'
import pdfIcon from '../../images/pdf.png'
import tickIcon from '../../images/tick.png'
import crossIcon from '../../images/cross.png'
import loadIcon from '../../images/reload.png'
import { 
    Button, 
    Overlay, 
    Popover, 
    Col,
    Tooltip, 
    OverlayTrigger 
} from 'react-bootstrap'
import { successStatusIcons, errorStatus } from '../../constants/file-uploads'

export default function PdfType(props){

    const { 
      data,
      status,
      title,
      url,
      handleClick,
      handleViewFunc,
      handleDeleteFunc,
      handleEditFunc,
      showView = true,
      showDelete = true,
      showEdit = true ,
      className, 
      key = '1'
    } = props;
    
    const [show, setShow] = useState(false);
    const [showPopup, setShowPopUp] = useState(false);
    const target = useRef(null);
    const overlayRef = useRef(null)

    const imageUrl = {
      0: loadIcon,
      1: tickIcon,
      2: crossIcon,
      3: crossIcon,
      4: tickIcon,
      5:crossIcon,
      6: crossIcon,
      7: tickIcon
    }


    const handleIconClick = (pdfUrl) => {
      
      if (typeof handleClick == 'function') {
        handleClick(pdfUrl)
      }

    }

    const handleView = (id) => {
      if (typeof handleViewFunc === 'function') {
          setShow(!show);
          handleViewFunc(id)
      } 
  }

  const handleDelete = (id) => {
      if (typeof handleDeleteFunc === 'function') {
          setShow(!show);
          handleDeleteFunc(id)
      }
  }
  const handleEdit = (id) => {
      if (typeof handleEditFunc === 'function') {
          setShow(!show);
          handleEditFunc(id)
      }
  }

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (overlayRef.current && !overlayRef.current.contains(event.target) && show) {
          setShow(!show);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [overlayRef, show]);

    const contextMenu = (e) => {
      if (status !==0 && !successStatusIcons.includes(status)) {
        e.preventDefault();
        setShow(!show);
      }
    }

    const handleShowTooltip = () => {
      setShowPopUp(!showPopup);
    }
    

    return (
      <Col
        md={3}
        sm={3}
        lg={3}
        className='text-center'
        key={key}
      > 
        <img
          onMouseEnter={handleShowTooltip}
          onMouseLeave={handleShowTooltip}
          className={`w-25 ${className ? className : ''}`}
          src={pdfIcon}
          alt="pdficon"
          ref={target}
          onClick={() => handleIconClick(url)} 
          onContextMenu={contextMenu}
        />
        <span style={{position: "absolute", top: "0", left: "45%"}}
          >
            <img alt="pdfstatus" className="w-25" src={imageUrl[status]}
        />
        </span>
        <p className="mb-2" style={{wordBreak: 'break-word'}}>{title}</p>
        <Overlay
        show={status !==0 && !successStatusIcons.includes(status) && showPopup}
        target={target.current}
        placement='top'
      >
          <Popover id="popover-contained">
            <Popover.Body>
              <span style={{color: 'red'}} ><i class="ri-alert-line"></i> {data.status ?  errorStatus[data.status] : errorStatus[3] }</span> 
            </Popover.Body>
        </Popover>
      </Overlay>

        {status !==0 && !successStatusIcons.includes(status) && 
        <Overlay
        show={show}
        target={target.current}
        placement='right'
        ref={overlayRef}
      >
          <Popover id="popover-contained">
            <Popover.Body>
              {showView && <Button
                onClick={() => handleView(data?.id)}
                variant="link"
                className="dropdown-item"
              >
                <i className="ri-eye-fill mr-2"></i>View
              </Button>}
              {showDelete && <Button
                onClick={() => handleDelete(data?.id)}
                variant="link"
                className="dropdown-item"
              >
                <i className="ri-delete-bin-6-fill mr-2">
                </i>Delete
              </Button>}
              {showEdit && <Button
                variant="link"
                className="dropdown-item"
                onClick={() => handleEdit(data?.id)}
              >
                <i className="ri-pencil-fill mr-2"></i>Edit
              </Button>}
            </Popover.Body>
        </Popover>
      </Overlay> }
      </Col>
        )
} 