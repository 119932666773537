// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f0f0f0;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
}

textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
}

.modal-actions button {
    margin-left: 10px;
    padding: 5px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/XlsxTable.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".table-container {\n    overflow-x: auto;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n}\n\nth, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n}\n\nth {\n    background-color: #f0f0f0;\n}\n\n.modal {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    border: 1px solid #ccc;\n    padding: 20px;\n    z-index: 1000;\n}\n\n.modal-content {\n    display: flex;\n    flex-direction: column;\n}\n\ntextarea {\n    width: 100%;\n    height: 100px;\n    margin-bottom: 10px;\n}\n\n.modal-actions {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.modal-actions button {\n    margin-left: 10px;\n    padding: 5px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
