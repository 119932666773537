import { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useNavigate, NavLink } from "react-router-dom"

import Icon from "./common/Icon"
import folderImage from '../images/folderplus.png'
import s3Icon from "../images/s3icon.png"
import infoIcon from '../images/info_icon.png'
import {
    getPeriods,
    addPeriod,
    resetStore,
    deletePeriod,
    getSinglePeriod,
    updatePeriod
 } from "../slices/periodReducer"
 import { updateClient, resetStore as clientResetStore } from "../slices/clientReducer"
 import CreateNewClient from "./clients/CreateNewClient"
import {CREATE_PERIOD_SCHEMA, CREATE_PERIOD_FIELDS} from '../constants/periods';
import {CREATE_CLIENT_SCHEMA, CREATE_CLIENT_FIELDS} from '../constants/clients';
import Loader from "./common/Loader";
import Base from "./Base"
import CreateNewPeriod from "./periods/CreatenewPeriod"
import { 
    Alert,
    Modal,
    Button, 
    Breadcrumb,
    Row,
    Col,
    Container
} from 'react-bootstrap';
import moment from 'moment';
import PaginationComponent from "./common/Pagination"
import ViewSelector from "./common/GridIconGenerator"
import ListView from "./common/ListView"
import { TABLE_HEADERS } from "../constants/periods"
import { PAGE_PERMISSIONS, CRUD_PERMISSIONS, PER_PAGE } from "../constants/user"
import useCheckAuthorization from "../hooks/checkAuthorization"


export default function Folder(){
    const dispatch = useDispatch()
    const { client_id} = useParams()
    const navigate = useNavigate();
    const [errors, setErrors] = useState({})
    const [show, openModal] = useState(false)
    const [showClient, openClientModal ] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [updateData, setValues] = useState({})
    const [showDeletModal, openDeleteModal] = useState(false)
    const [delete_id, setDeleteId] = useState(null)
    const { 
        data,
        loading,
        error,
        client,
        success,
        successMsg,
        total_count,
        period,
        
    } = useSelector((state) => state.periods)

    const {
        error: clientError,
        success: clientSuccess,
        successMsg: clientSuccessMsg
    } = useSelector((state) => state.clients)

    const {gridView} = useSelector(state => state.common)
    
    const [current_page, setCurrentPage] = useState(1);
    const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.period)
    const editPermission = useCheckAuthorization(CRUD_PERMISSIONS.period.edit)
    const editClientPermission = useCheckAuthorization(CRUD_PERMISSIONS.client.edit)
    const deletePermission = useCheckAuthorization(CRUD_PERMISSIONS.period.delete)
    const addPermission  = useCheckAuthorization(CRUD_PERMISSIONS.period.add)

    useEffect(() => {
        if (!hasPermission) {
            navigate('/authorization-error');
        }
        return () =>  {
            dispatch(resetStore('all'))
            dispatch(clientResetStore())
        }
    }, [])
    useEffect(()=> {
        dispatch(getPeriods({id:client_id,page_number: current_page}));
    }, [client_id])

    useEffect(()=>{
        if (period.id) {
            setIsUpdate(true) 
            setValues(period)
            openModal(true)
        }
    },[period])

    

    const onSubmit = async (e, isUpdate) => {
        e.preventDefault();
        const values = {};
        Object.keys(CREATE_PERIOD_FIELDS).forEach(field => {
            values[field] = e.target[field].value;
        })
        try {
             const data = await CREATE_PERIOD_SCHEMA.validate(values, { abortEarly: false });
             if (data) {
                data.start_date = moment(data.start_date).format('YYYY-MM-DD')
                data.end_date = moment(data.end_date).format('YYYY-MM-DD')
                if (!isUpdate) {
                    dispatch(addPeriod({client_id, current_page, ...data}));
                }
                if (isUpdate) {
                    dispatch(updatePeriod({data,  page_number: current_page, id: updateData['id'], client_id: client.id}));
                    setIsUpdate(false)
                    setValues({})
                }
                setErrors({})
                openModal(false );
             }
          } catch (err) {
            const errData =[];
            err.inner.forEach(e => {
                errData[e.path] = e.message
                setErrors(errData);
            });
          }

        
    }

    const handleOpen =() => {
        openModal(true);
    }

    const handleModalClose = () => {
        openModal(false );
    }

    const handleFolder = (id) => {
        navigate(`/files/${id}`)
    }

    const handleEdit = (id) => {
        dispatch(getSinglePeriod({id,client_id: client.id}))
    }

    const handleDelete = (id) => {
        setDeleteId(id)
        openDeleteModal(true)
    }

    const handleDeleteModalConfirm = () => {
        dispatch(deletePeriod({
            id: delete_id,
            client_id: client.id,
            page_number: current_page
        }))
        setDeleteId(null)
        openDeleteModal(false)
    }

    const handleDeleteModalClose = () => {
        setDeleteId(null)
        openDeleteModal(false)
    }

    const handlePagination = (number) => {
        setCurrentPage(number)
        dispatch(getPeriods({id:client_id,page_number: number}));
    }


    const onClientSubmit = async (e, isUpdate) => {
        e.preventDefault();
        const values = {};
        Object.keys(CREATE_CLIENT_FIELDS).forEach(field => {
            values[field] = e.target[field].value;
        })
        try {
             const data = await CREATE_CLIENT_SCHEMA.validate(values, { abortEarly: false });
                if (data) {
                    dispatch(updateClient({data, page_number: 1, client_id: client.id}));
                }
                openClientModal(false);
                setErrors({})
                setIsUpdate(false)
             
          } catch (err) {
            const errData =[];
            err.inner.forEach(e => {
                errData[e.path] = e.message
                setErrors(errData);
            });
          }

          

        
    }

    const handleClientModalClose = () => {
        openClientModal(false );
        setIsUpdate(false)
        dispatch(resetStore())
    }



    return (<Base header_title={`${client && client.client_name} - Periods` }>
                <Container>
                   <Row className="mb-2">
                      <Col
                      md={11}
                      sm={11}
                      lg={11}
                      >
                        <Breadcrumb>
                            <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/'}}>Dashboard</Breadcrumb.Item>
                            <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/statement3'}}>
                                    Statement3
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Client</Breadcrumb.Item>
                        </Breadcrumb>
                      </Col> 
                      <Col
                        md={1}
                        sm={1}
                        lg={1}
                      >
                        <ViewSelector/>
                      </Col> 
                   </Row>     
                {(success || error  || clientSuccess || clientError) && (
                   <Row>
                    <Col
                        md={12}
                        className="justify-content-center d-flex"
                    >
                        <Alert
                         dismissible
                         transition
                        variant={success || clientSuccess? 'success' : 'danger'}
                        onClose={() => dispatch(resetStore())}
                        >{success ? successMsg : error}
                        {clientSuccess ? clientSuccessMsg : clientError}
                        </Alert>

                    </Col>
                   </Row> )}
                    {gridView == 'grid' &&
                        <Row>
                        <Icon 
                            title={client && client.client_name} imageUrl={infoIcon}
                             onClickFunc={editClientPermission ? () => { openClientModal(true); setIsUpdate(true) }: undefined} 
                        />
                        {addPermission && <Icon 
                            title="Add New Period &raquo;"
                            imageUrl={folderImage}
                            onClickFunc={handleOpen} 
                        />}
                        {Array.isArray(data) && data.map(folder => {
                        return  (<Icon 
                            data={folder}
                            title={folder.folder_name}
                            imageUrl={s3Icon}
                            onClickFunc={() => handleFolder(folder.id)} 
                            />)
                        })}
                    </Row>}
                {gridView == 'list' &&
                    <Row>
                    <ListView 
                        headers={TABLE_HEADERS}
                        data={Array.isArray(data) ? data: []}
                        handleViewFunc={(id) => handleFolder(id)}
                        handleDeleteFunc={(id) => handleDelete(id)}
                        handleEditFunc={(id) => handleEdit(id)}
                        showDelete={deletePermission}
                        showEdit={editPermission}
                    />
                </Row> }   
                {total_count > PER_PAGE  &&<Row>
                    <Col
                    md={12}
                    className="d-flex justify-content-center"
                    ></Col>
                   
                    <PaginationComponent total_count={total_count} current_page={current_page} handleOnClick={handlePagination}/>
                    </Row>
                   
                }
                <Modal show={showDeletModal}>
                    <Modal.Header>Delete Period</Modal.Header>
                    <Modal.Body>
                    <p>Are you sure you want to delete period ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleDeleteModalConfirm}>Delete</Button>
                        <Button onClick={handleDeleteModalClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <CreateNewPeriod 
                    fields={CREATE_PERIOD_FIELDS}
                    handleSubmit={onSubmit}
                    errors={errors}
                    show={show}
                    onHideFunc={handleModalClose}
                    isUpdate={isUpdate}
                    values={updateData}
                />
                <CreateNewClient
                 fields={CREATE_CLIENT_FIELDS}
                 handleSubmit={onClientSubmit}
                 errors={errors}
                 show={showClient}
                 onHideFunc={handleClientModalClose}
                 isUpdate={isUpdate}
                 values={client || {}}
                />
                {loading && (<Loader/>)}
                </Container>
            </Base>)
}
