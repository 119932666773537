import _ from 'lodash';

export const getPermissionsFromRole = (role) => {
    let permissions = [];
    if (role.permissions) {
      permissions =  _.map(role.permissions, 'codename')
    }
    return permissions
}


export const errorMsgCreator= (msg, data) => {
  if (Object.keys(data).length !== 0 ) {
    msg += `${data[Object.keys(data)[0]]}`
  }
  return msg
}