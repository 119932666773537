import { useEffect, useState } from "react";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';


export default function PaginationComponent(props) {
  
    const [total_pages, setTotalPages] = useState(0)
    const { 
      total_count, 
      current_page , 
      handleOnClick, 
      per_page =25
    } = props;
    

    useEffect(() => {
        const pages = Math.ceil(total_count/per_page);
        setTotalPages(pages)
  

    }, [total_count, current_page])

    return (total_pages > 1 && ( <ResponsivePagination
      current={current_page}
      total={total_pages}
      onPageChange={handleOnClick}
    />))

}