import React from "react";
import "./CurrencyDropdown.css";

const CurrencyDropdown = ({
  index,
  currency,
  handleCurrencyChange,
  currencyData,
}) => {
  return (
    <div className="currency-dropdown">
      <select
        id={`currency-code-${index}`}
        value={currency.currency}
        onChange={(e) =>
          handleCurrencyChange(index, "currency", e.target.value)
        }
        className="form-control"
      >
        <option value="" disabled>
          Select Currency
        </option>
        {currencyData.map((cur) => (
          <option key={cur.id} value={cur.id}>
            {cur.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CurrencyDropdown;
