import React, { useEffect, useLayoutEffect, useState } from "react";
import Base from "../Base";
import FileUpload from "../common/FileUpload";
import instance from "../../services/baseService";
import { Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { HiDownload } from "react-icons/hi";
import { FaCheck, FaCircleDot, FaCircleUser } from "react-icons/fa6";
import toast from "react-hot-toast";
import { GoCommentDiscussion } from "react-icons/go";
import RefundForm from "./RefundForm";
import RefundList from "./RefundList";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Badge,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ChatPopup from "../common/ChatPopup";
import ViewSheetSummary from "../ViewSheetSummary";
import { CRUD_PERMISSIONS } from "../../constants/user";
import useCheckAuthorization from "../../hooks/checkAuthorization";

const Refunds = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [rightTab, setRightTab] = useState(0);
  const { selectedMonth, selectedYear } = useSelector((state) => state.common);
  const [loading, setLoading] = useState(false);
  const [summaryFile, setSummaryFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadingBill, setUploadingBill] = useState(false);
  const [sheetData, setSheetData] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const [sheetSummaryData, setSheetSummaryData] = useState(null);
  const [reloadSheet, setReloadSheet] = useState(false);

  const [allComments, setAllComments] = useState([]);
  const [file, setFile] = useState(null);
  const [billsData, setBillsData] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [existingRefundItem, setExistingRefundItem] = useState({});
  const [refundStatusOptions, setRefundStatusOptions] = useState([]);
  const [periodData, setPeriodData] = useState([]);
  const [periodId, setPeriodId] = useState("");
  const { id: userId } = useSelector((state) => state.common);
  const { client_id } = useParams();
  const manageAllClientsPermission = useCheckAuthorization(CRUD_PERMISSIONS.sheet.manage_clients);

  const tabs = ["Shipping Bill", "Sales Register", "Invoices", "Others"];

  const getSheet = async (period_id) => {
    try {
      setLoading(true);
      setSheetData(null);
      const res = await instance.post(
        `/api/v1/client/refund/view/sheet/${client_id}`,
        {
          folder_id: period_id
        }
      );
      if(res.data?.data)
        setSheetSummaryData(res.data?.data);
      if (res.data?.data?.sheet !== null) {
        setSheetData(res.data?.data?.sheet);
        //setReloadSheet(true);
      }
      setAllComments(res.data?.data?.all_comments);
      setSummaryData(res.data?.data?.summery);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const getPeriodData = async() => {
    try {
      const res = await instance.get(`/api/v1/client/refund/folder/lists/${client_id}/1/10`);
      setPeriodData(res.data.data.list);
    } catch (error) {
      console.error('error', error);
    }
  }

  const handlePeriodSelect = async (period_id) => {
    setPeriodId(period_id);
    if(period_id){
      await getSheet(period_id);
    }else{
      setSheetData(null);
      setSheetSummaryData(null);
    }
  }


  const submit = async() => {
    if(!periodId){
      toast('Please Select a valid Period');
    }
    setUploadingBill(true);
    setReloadSheet(false);
    try {
      setLoading(true);
      const promise = instance.post(
        `/api/v1/client/refund/sheet/upload/${client_id}`,
        {
          folder_id: periodId,
          file: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const response = await toast.promise(promise, {
        loading: "Uploading...",
        success: <b>Uploaded!</b>,
        error: <b>Uploading Failed.</b>,
      });
      console.log('response', response);
      if(response.data.type === 'success')
        getSheet(periodId);
    } catch (error) {
      console.error("error", error);
    } finally {
      setFile(null);
      setUploadingBill(false);
    }
  };

  const downloadBillsZip = async (item) => {
    try {
      const promise = instance.post(
        `/api/v1/client/refund/download/zip/${client_id}`,
        {
          note_id: item.id,
        }
      );
      const response = await toast.promise(promise, {
        loading: "Downloading...",
        success: <b>Downloaded!</b>,
        error: <b>Downloading Failed.</b>,
      });
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      const fileName = item.notes.replace(/ /g, "_");
      link.setAttribute('download', `${fileName}.zip`); // Specify the filename

      // Append to the document and click the link to trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error("error", error);
    } finally {
    }
  }

  const getAllGstFiles = async () => {
    try {
      setLoading(true);
      const res = await instance.post(
        `/api/v1/client/refund/list/files/${client_id}`,
        {
          folder_id: periodId,
          section_type: activeSubTab + 1,
        }
      );
      console.log(res);
      const data = res.data.data.list.map((item) => ({
        key: item?.id,
        label: (
          <p className="font-semibold text-base flex items-start gap-x-2">
            {" "}
            <span className="bg-[#0f4372] text-white rounded-full p-px px-2">
              {item.refund_note_files?.length}
            </span>
            <div className="flex flex-col">
              {item.notes ? item.notes : "Summary"}
              <span className="text-xs font-normal">{moment(item?.updated_date).format('DD-MM-YYYY')}</span>
            </div>
          </p>
        ),
        section: item?.section,
        children: (
          <div>
            <div class="pfa-down-all"><a class="badge badge-success1" href="#" onClick={() => downloadBillsZip(item)} >Download all <i class="las la-download"></i></a></div>
            <ul className="divide-y">
              {item?.refund_note_files.map((file, i) => (
                <li
                  key={i}
                  className="flex justify-between uppercase py-3 text-[#0f4372]"
                >
                  {file?.file_name}{" "}
                  <a
                    href={file?.url}
                    className="bg-[#0f4372] rounded-[100%] text-white flex items-center justify-center p-1"
                    target="_blank"
                    download
                  >
                    <HiDownload className="text-lg" />
                  </a>
                </li>
              ))}
            </ul>
            {item.notes && (
              <p className="pa-acc-notes alert alert-success">{item.notes}</p>
            )}
          </div>
        ),
      }));
      setBillsData(data);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };
  const getRefundData = async () => {
    try {
      setLoading(true);
      const res = await instance.post(
        `/api/v1/client/refund/list/arn/${client_id}`,
        {
          folder_id: periodId,
          page: 1,
          perpage: 5,
          arn_date: moment(),
        }
      );

      setRefundData(res.data.data.list);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };
  const handleRefundSubmit = async (
    { arnDate, arnNo, arnStatus },
    refundId
  ) => {
    try {
      setLoading(true);
      let promise;
      if (refundId) {
        promise = instance.post(
          `api/v1/client/refund/update/arn/${client_id}/${refundId}`,
          {
            folder_id: periodId,
            arn_no: arnNo,
            arn_date: arnDate,
            status: arnStatus,
          }
        );
      } else {
        promise = instance.post(`api/v1/client/refund/add/arn/${client_id}`, {
          folder_id: periodId,
          arn_no: arnNo,
          arn_date: arnDate,
          status: arnStatus,
        });
      }

      await toast.promise(promise, {
        loading: "Saving...",
        success: <b>Refund Saved Successfully!</b>,
        error: <b>Adding Refund Failed.</b>,
      });
      await getRefundData();
      setExistingRefundItem({});
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
    //dispatch(createUser({ name, email, role, password, phone }));
  };

  const handleRefundDelete = async (refundId) => {
    try {
      // Show confirm dialog
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this item?"
      );

      if (isConfirmed) {
        setLoading(true);
        const promise = instance.post(
          `api/v1/client/refund/delete/arn/${client_id}/${refundId}`,
          {
            folder_id: periodId
          }
        );

        await toast.promise(promise, {
          loading: "Deleting...",
          success: <b>Refund Deleted Successfully!</b>,
          error: <b>Deleting Refund Failed.</b>,
        });
        await getRefundData();
        // Perform delete action
      } else {
        console.log("Deletion cancelled");
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSummaryFileChange = (files) => {
    const selectedFiles = Array.from(files); // Convert FileList to array
    setSummaryFile(selectedFiles);
    uploadSummaryFiles(selectedFiles);
  };

  const uploadSummaryFiles = async (files) => {
    setUploading(true);
    const formData = new FormData();
    files.forEach((fileSingle) => {
      formData.append("files", fileSingle);
    });
    formData.append("folder_id", periodId);
    try {
      const promise = instance.post(
        `/api/v1/client/refund/upload/arsheet/${client_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const res = await toast.promise(promise, {
        loading: "Uploading...",
        success: <b>Uploaded!</b>,
        error: <b>Uploading Failed.</b>,
      });
      getSheet(periodId);
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast(error?.response?.data?.message);
      }
    } finally {
      setSummaryFile(null);
      setUploading(false);
    }
  };

  const handleSummaryApprove = async (id, status) => {
    setLoading(true);
    try {
      const promise = instance.post(
        `/api/v1/client/refund/summery/approve/${client_id}/${id}`,
        {
          folder_id: periodId,
          approve: status,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await toast.promise(promise, {
        loading: "Submitting...",
        success: (response) => <b>{response.data.message || "Summary Submitted Successfully!"}</b>,
        error: (error) => <b>{error.response?.data?.message || "Submission Failed."}</b>,
      });
      getSheet();
      
    } catch (error) {
      console.error("error", error);
      if(error?.response?.data?.message){
        //toast(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  const hadleDeleteSheet = async (sheetId) => {
    try {
      setLoading(true);
      const promise = instance.post(`/api/v1/client/refund/delete/sheet/${client_id}`, {
        sheet_id: sheetId,
      });
      const response = await toast.promise(promise, {
        loading: "Deleting...",
        success: <b>Deleted!</b>,
        error: <b>Deleting Failed.</b>,
      });
      getSheet(periodId);
      
      
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (file) {
      submit();
      //console.log("file", file);
    }
  }, [file]);

  useEffect(() => {
    getAllGstFiles();
  }, [selectedMonth, selectedYear, activeSubTab]);

  useEffect(() => {
    const getRefundStatuses = async () => {
      try {
        setLoading(true);
        const res = await instance.get(
          `/api/v1/client/refund/status/list/${client_id}`
        );
        //console.log('status', res);
        setRefundStatusOptions(res.data.data.lists);
      } catch (error) {
        console.error("error", error);
      } finally {
        setLoading(false);
      }
    };
    if (activeTab === 0) getPeriodData();
    if (activeTab === 0 && periodId) getSheet(periodId);
    if (activeTab === 1 && periodId) getAllGstFiles();
    if (activeTab === 2 && periodId) {
      getRefundStatuses();
      getRefundData();
    }
  }, [selectedMonth, selectedYear, activeTab, periodId]);

  const refundEditAction = ({ id, arn_no, arn_date, status }) => {
    const refundItem = {
      id: id,
      arnNo: arn_no,
      arnDate: arn_date,
      arnStatus: status,
    };
    setExistingRefundItem(refundItem);
  };

  const sheetSubmitAction = async (sheetId, status) => {
    setLoading(true);
    try {
      const promise = instance.post(
        `/api/v1/client/refund/approve/${client_id}`,
        {
          folder_id: periodId,
          approve: status,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await toast.promise(promise, {
        loading: "Submitting...",
        success: <b>Submitted Successfully!</b>,
        error: <b>Submission Failed.</b>,
      });
      getSheet(periodId);
    } catch (error) {
      console.error("error", error);
      if (error?.response?.data?.message) {
        toast(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSheetSubmitManager = async (sheetId, status) => {
    setLoading(true);
    try {
      const promise = instance.post(
        `/api/v1/client/refund/manager/approve/${client_id}/${sheetId}`,
        {
          folder_id: periodId,
          approve: status,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await toast.promise(promise, {
        loading: "Submitting...",
        success: <b>Submitted Successfully!</b>,
        error: <b>Submission Failed.</b>,
      });
      getSheet(periodId);
    } catch (error) {
      console.error("error", error);
      if (error?.response?.data?.message) {
        toast(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const customExpandIcon = ({ isActive }) => {
    return isActive ? <MinusOutlined size={36} /> : <PlusOutlined size={36} />;
  };

  return (
    <Base header_title="Refunds" hideDatepicker={true}>
      <div className="bg-white shadow-sm rounded-2xl w-full h-full px-3 py-4 space-x-3">
        {/* Period selection */}
        <Row className="pb-10">
          {/* First Column */}
          <Col md={2}>
            <Form.Group controlId="periodSelection">
              <Form.Label>Select Period</Form.Label>
              <Form.Control
                as="select"
                value={periodId}
                onChange={(e) => handlePeriodSelect(e.target.value)}
              >
                <option value="">Select a period</option>
                {periodData.map(periodItem => 
                  <option value={periodItem.id} key={periodItem.id}>{periodItem.folder_name}</option>
                )}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        {/* Period selection */}
        <hr />
        {!periodId ? <h4 className="text-center mt-4">Please Selece a Period</h4> : <>
          <div className="mb-3">
          <span
            onClick={() => setActiveTab(0)}
            className={`${
              activeTab === 0 ? "bg-[#0d4473] text-white" : ""
            } cursor-pointer p-2 px-3 rounded-full relative`}
          >
            View Form
            {activeTab == 0 && (
              <div className=" absolute w-0 h-0 left-10 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
            )}
          </span>
          <span
            onClick={() => setActiveTab(1)}
            className={`${
              activeTab === 1 ? "bg-[#0d4473] text-white" : ""
            } cursor-pointer p-2 px-3 rounded-full relative`}
          >
            Download Bills
            {activeTab == 1 && (
              <div className=" absolute w-0 h-0 left-14 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
            )}
          </span>
          <span
            onClick={() => setActiveTab(2)}
            className={`${
              activeTab === 2 ? "bg-[#0d4473] text-white" : ""
            } cursor-pointer p-2 px-3 rounded-full relative`}
          >
            Refund status
            {activeTab === 2 && (
              <div className=" absolute w-0 h-0 left-14 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
            )}
          </span>
        </div>
        {activeTab === 0 && (
          <div>
            <div>
              <h1 className="text-xl flex gap-x-2 items-center">
                <img
                  className="w-10"
                  src="../assets/icons/up-loading.png"
                  alt="Upload Icon"
                />
                Upload summary for client approval
              </h1>
              {uploadingBill ? (
                <div className="text-center">Uploading...</div>
              ) : (
                <FileUpload
                  file={file}
                  setFile={setFile}
                  inputId="bills"
                  allowMultiple={false}
                />
              )}
            </div>
            <div className="flex mt-5 gap-x-5">
              <div className="w-full overflow-x-scroll">
                {/* <XlsxTableRefund file={file} reloadSheet={reloadSheet}/> */}
                {!periodId ? <h4 className="text-center mt-4">Please Selece a Period</h4>: (!sheetSummaryData  ? <h4 className="text-center mt-4">No Summary Uploaded For the period !</h4> : <ViewSheetSummary sheetSummaryData={sheetSummaryData} handleSheetSubmit={sheetSubmitAction} handleSheetSubmitManager={handleSheetSubmitManager} sheetType="refunds" getSheet={getSheet} periodId={periodId} hadleDeleteSheet={hadleDeleteSheet}/>) }
              </div>
              <div className="min-w-[16rem] bg-[#fafafa] mt-[19px] rounded-md p-3">
                <div className="file-loading file-loading-comment">
                  <h4
                    className="jl-heading mt-0 card-title bold-font mb-3"
                    id="Filechange"
                  >
                    Upload Summary
                  </h4>
                  {uploading ? (
                    <div className="text-center">Uploading...</div>
                  ) : (
                    <FileUpload
                      file={summaryFile}
                      setFile={handleSummaryFileChange}
                      inputId="summary"
                      allowMultiple={true}
                    />
                  )}
                </div>
                <div className="mb-3 mt-5">
                  <span
                    onClick={() => {setRightTab(0); getSheet();}}
                    className={`${
                      rightTab === 0 ? "bg-[#0d4473] text-white" : ""
                    } text-xs cursor-pointer p-2 px-3 rounded-full relative`}
                  >
                    Download
                    {rightTab == 0 && (
                      <div className=" absolute w-0 h-0 left-10 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
                    )}
                  </span>
                  <span
                    onClick={() => {setRightTab(1); getSheet();}}
                    className={`${
                      rightTab === 1 ? "bg-[#0d4473] text-white" : ""
                    } text-xs cursor-pointer p-2 px-3 rounded-full relative`}
                  >
                    Comments{" "}
                    {rightTab == 1 && (
                      <div className=" absolute w-0 h-0 left-14 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
                    )}
                  </span>
                </div>

                {rightTab === 0 ? (
                  <ul className="list-group list-group-flush r-list-group r-list-group-icon">
                    {summaryData.map((summary) => (
                      <li key={summary.id} class="list-group-item bg-white">
                        <a href={summary.url} target="_blank">
                          <i class="las la-file-pdf pa-text-danger mx-2"></i>
                          {summary.file_name}
                        </a>
                        <small className="text-[#212529]">{moment(summary.updated_date).format('DD-MM-YYYY')}</small>
                        <div className="mt-2 d-flex align-items-center gap-2">
                          {summary.preface_approval === 1 && (
                            <Badge bg="primary">Approved</Badge>
                          )}
                          {summary.preface_approval === 2 && (
                            <Badge bg="light">Rejected</Badge>
                          )}
                          {!manageAllClientsPermission && summary.preface_approval === 0 && (
                            <Badge bg="warning">Waiting</Badge>
                          )}
                          {manageAllClientsPermission && summary.preface_approval === 0 && (
                            <>
                              <Button
                                variant="primary"
                                size="sm"
                                className="py-0 px-1 !text-[12px]"
                                onClick={() => handleSummaryApprove(summary.id, 1)}
                              >
                                Approve
                              </Button>
                              <Button
                                variant="outline-primary"
                                size="sm"
                                className="py-0 px-1 !text-[12px]"
                                onClick={() => handleSummaryApprove(summary.id, 2)}
                              >
                                Reject
                              </Button>
                            </>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="flex flex-col overflow-y-scroll h-[13rem]  mt-5 divide-y">
                    {allComments.map((comment) => (
                      <p
                        className={`hover:bg-[#f1f1f1] text-xs flex flex-col m-0 items-start gap-x-2 py-3 px-2 rounded-md cursor-pointer ${
                          comment.status !== 4 ? "justify-between" : ""
                        }`}
                      >
                        <div className="flex w-full items-center gap-x-2">
                        {comment.status === 4 ? (
                          <FaCircleUser size={18} color="#fcbba5 " />
                        ) : (
                          <GoCommentDiscussion size={18} color="#fcbba5 " />
                        )}
                        <span>{comment.comment}</span>
                        {comment.status && (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id={`button-tooltip-${comment.id}`}>
                                {comment.status === 1 && "Approved"}
                                {comment.status === 2 && "Rejected"}
                                {comment.status === 4 && "Resolved"}
                              </Tooltip>
                            }
                            container={document.body}
                          >
                            <span
                              id={`button-tooltip-${comment.id}`}
                              className="ml-auto"
                            >
                              <FaCircleDot
                                className={`${
                                  comment.status === 1 && "text-success"
                                } ${comment.status === 4 && "text-success"} ${
                                  comment.status === 2 && "text-danger"
                                } `}
                              />
                            </span>
                          </OverlayTrigger>
                        )}
                        </div>
                        {/* Second line: Profile name */}
                        <div className="mt-1 text-gray-600">
                          <span className="text-xs">Added by: {comment.created_user_profile?.name}</span>
                        </div>
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <div className="flex gap-x-3 -mb-8">
              {tabs.map((tab, i) => (
                <p
                  key={i}
                  className={`${
                    activeSubTab === i ? "bg-[#333333] text-white" : ""
                  } flex items-center gap-x-2 rounded-ss-md rounded-se-md px-2 py-1 cursor-pointer hover:bg-[#333333] hover:text-white`}
                  onClick={() => setActiveSubTab(i)}
                >
                  {tab}
                  {activeSubTab === i && (
                    <span className="bg-green-500 text-white h-fit rounded-full p-px">
                      <FaCheck />
                    </span>
                  )}
                </p>
              ))}
            </div>
            <hr className="border-blue-500 mb-5" />
            <div className="bg-[#fafafa] p-4 rounded-lg">
              <h1 className="text-xl mb-3 flex items-center gap-x-2">
                {" "}
                <img
                  className="w-10"
                  src="../assets/icons/up-loading.png"
                  alt="Upload Icon"
                />{" "}
                Download Shipping Bill
              </h1>
              <Collapse
                accordion
                items={billsData}
                expandIcon={customExpandIcon}
                expandIconPosition={"end"}
              />
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <RefundForm
              submitFunc={handleRefundSubmit}
              existingRefundItem={existingRefundItem}
              refundStatusOptions={refundStatusOptions}
            />
            {loading ? (
              <p className="text-center my-4">...loading</p>
            ) : (
              <RefundList
                refundData={refundData}
                refundEditAction={refundEditAction}
                handleRefundDelete={handleRefundDelete}
              />
            )}
          </div>
        )}
        </>}
      </div>
      <ChatPopup client_id={client_id} chat_type="REFUND" />
    </Base>
  );
};

export default Refunds;
