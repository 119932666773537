import {string, date,object,ref} from 'yup';
import moment from 'moment';
export const ROUTES = {
    LIST_PERIODS:  (id, page_number) =>  `/api/v1/statement/folder/list/${id}/${page_number}`,
    CREATE_PERIOD: (id) => `/api/v1/statement/folder/create/${id}`,
    DELETE_PERIOD: (id, client_id) => `/api/v1/statement/folder/delete/${client_id}/${id}`,
    SINGLE_PERIOD: (id, client_id) => `/api/v1/statement/folder/view/${client_id}/${id}`,
    UPDATE_PERIOD: (id, client_id) => `/api/v1/statement/folder/update/${client_id}/${id}`
}

export const CREATE_PERIOD_SCHEMA = object().shape({
    folder_name: string().required("Folder Name is required"),
    start_date: date().required("Start Date is required"),
    end_date : date().min(
        ref('start_date'),
        "End date can't be before Start date"
      ).required("End Date is required"),
    note: string()
})


export const CREATE_PERIOD_FIELDS = {
        folder_name: { name: 'folder_name', placeholder: 'Folder Name', type: 'text', label: 'Folder Name'} ,
        start_date: { name: 'start_date', placeholder: 'GST Number', type: 'date', label: 'Start Date'},
        end_date : { name: 'end_date', placeholder: 'Phone Number', type: 'date', label: 'End Date'},
        note: { name: 'note', placeholder: 'Notes', type: 'text', label: 'Notes'},
}


export const TABLE_HEADERS = [
    {
        id: 'folder_name',
        name: 'NAME'
    },
    {
        id: 'start_date',
        name: 'START DATE',
    },
    {
        id: 'end_date',
        name: 'END DATE',
    },
    {
        id: 'updated_date',
        name: 'LAST UPDATED',
        modifier: (date) => moment(date).format('YYYY-MM-DD')
    }
]