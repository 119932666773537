import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '../services/baseService'
import { ROUTES, CURRENT_PAGE, PER_PAGE } from '../constants/user'
import { errorMsgCreator } from '../utils'

export const getUsers = createAsyncThunk('users/getUsers', async ({page, search}, thunkAPI) => {
  const data = {
    page: CURRENT_PAGE,
    perpage: PER_PAGE
  }

  if (page ) data.page  = page
  if (search) data.q = search
  try{
  const response = await instance.post(ROUTES.LIST_USER, data)
  return response.data
} catch(err) {
  return thunkAPI.rejectWithValue(err?.response?.data) 
}
})

export const getActiveUsers = createAsyncThunk('users/getActiveUsers', async ({page, search}, thunkAPI) => {
  const data = {
    page: CURRENT_PAGE,
    perpage: PER_PAGE
  }

  if (page ) data.page  = page
  if (search) data.q = search
  try{
  const response = await instance.post(ROUTES.LIST_ACTIVE_USER, data)
  return response.data
} catch(err) {
  return thunkAPI.rejectWithValue(err?.response?.data) 
}
})

export const getRoles = createAsyncThunk('users/getRoles', async (data = {}, thunkAPI) => {
  try {
    const response = await instance.get(ROUTES.LIST_ROLES)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const getSingleUser = createAsyncThunk('users/getSingleUser', async (user_id, thunkAPI) => {
  try {
    const response = await instance.get(ROUTES.LIST_SINGLE_USER(user_id))
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const createUser = createAsyncThunk('users/createUser', async (data, thunkAPI) => {
  try {
    const response = await instance.post(ROUTES.CREATE_USER, data)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const updateStatus = createAsyncThunk('users/updateStatus  ', async ({id, active, page}, thunkAPI) => {
  let current_page  = page ? page: CURRENT_PAGE
  try {
    const response = await instance.post(ROUTES.UPDATE_STATUS(id), { active })
    thunkAPI.dispatch(getUsers({ page: current_page }))
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const editUser =  createAsyncThunk('users/editUser', async ({data, id}, thunkAPI) => {
  try {
    const response = await instance.post(ROUTES.EDIT_USER(id), data)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const getAssignedClients = createAsyncThunk('users/getAssignedClients', async ({data, id}, thunkAPI) => {
  try {
    const response = await instance.post(ROUTES.ASSIGNED_CLIENTS(id), data)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const getUnassignedClients = createAsyncThunk('users/getUnassignedClients', async ({data, id}, thunkAPI) => {
  try {
    const response = await instance.post(ROUTES.UNASSIGNED_CLIENTS(id), data)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})


export const assignClients = createAsyncThunk('users/assignedClients', async ({data, id}, thunkAPI) => {
  try {
    const response = await instance.post(ROUTES.ASSIGN_CLIENT(id), data)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})


export const removeClient = createAsyncThunk('users/removeClient', async ({data, id}, thunkAPI) => {
  try {
  const response = await instance.post(ROUTES.REMOVE_CLIENT(id), data)
  return response.data
} catch (err) {
  return thunkAPI.rejectWithValue(err?.response?.data)
}
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: [],
    activeUsers: [],
    user: {},
    loading: false,
    error: null,
    success: false,
    successMsg: null, 
    total_count: 0,
    total_unassigned_count: 0,
    total_assigned_count: 0,
    roles: [],
    assigned_clients: [],
    unassigned_clients: []
  },
  reducers: {
    resetStore(state){
      state.error = null
      state.success = false
      state.successMsg = null
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload.data?.lists
        state.loading = false
        state.total_count = action.payload.data?.total_count
    })
    builder.addCase(getUsers.rejected, (state, action) => {
        state.loading = false
        let data = action.payload.data
        state.error = errorMsgCreator('Unable to fetch user list. ',data)
    })
    builder.addCase(getActiveUsers.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getActiveUsers.fulfilled, (state, action) => {
        state.activeUsers = action.payload.data?.lists
        state.loading = false
        state.total_count = action.payload.data?.total_count
    })
    builder.addCase(getActiveUsers.rejected, (state, action) => {
        state.loading = false
        let data = action.payload.data
        state.error = errorMsgCreator('Unable to fetch active user list. ',data)
    })
    builder.addCase(getRoles.pending, (state, action) => {
      state.loading = true
  })
  builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload.data?.roles
  })
  builder.addCase(getRoles.rejected, (state, action) => {
      state.loading = false
      let data = action.payload.data
      state.error = errorMsgCreator('Unable to fetch role list. ',data)

  })
  builder.addCase(getSingleUser.pending, (state, action) => {
    state.loading = true
})
builder.addCase(getSingleUser.fulfilled, (state, action) => {
    state.user = action.payload.data?.profile
})
builder.addCase(getSingleUser.rejected, (state, action) => {
    state.loading = false
    let data = action.payload.data
    state.error = errorMsgCreator('Unable to fetch user. ',data)

})
  builder.addCase(createUser.pending, (state, action) => {
    state.loading = true
})
builder.addCase(createUser.fulfilled, (state, action) => {
  state.loading = false
  state.success = true
  state.successMsg = 'Successfully created user'
})
builder.addCase(createUser.rejected, (state, action) => {
    state.loading = false
    let data = action.payload.data
    state.error = errorMsgCreator('Unable to create user. ',data)
})
builder.addCase(editUser.pending, (state, action) => {
  state.loading = true
})
builder.addCase(editUser.fulfilled, (state, action) => {
state.loading = false
state.success = true
state.successMsg = 'Successfully updated user'
})
builder.addCase(editUser.rejected, (state, action) => {
  state.loading = false
  let data = action.payload.data
  state.error = errorMsgCreator('Unable to edit user. ',data)
})
builder.addCase(updateStatus.pending, (state, action) => {
  state.loading = true
})
builder.addCase(updateStatus.fulfilled, (state, action) => {
state.loading = false
state.success = true
state.successMsg = 'Successfully updated status'
})
builder.addCase(updateStatus.rejected, (state, action) => {
  state.loading = false
  let data = action.payload.data
  state.error = errorMsgCreator('Unable to update status. ',data)
})
builder.addCase(getAssignedClients.pending, (state, action) => {
  state.loading = true
})
builder.addCase(getAssignedClients.fulfilled, (state, action) => {
state.assigned_clients = action.payload.data?.lists
state.total_assigned_count = action.payload.data?.total_count
state.loading = false
})
builder.addCase(getAssignedClients.rejected, (state, action) => {
  state.loading = false
  let data = action.payload.data
  state.error = errorMsgCreator('Unable to fetch assigned clients. ',data)
})
builder.addCase(getUnassignedClients.pending, (state, action) => {
  state.loading = true
})
builder.addCase(getUnassignedClients.fulfilled, (state, action) => {
state.unassigned_clients = action.payload.data?.lists
state.total_unassigned_count = action.payload.data?.total_count
state.loading = false
})
builder.addCase(getUnassignedClients.rejected, (state, action) => {
  state.loading = false
  let data = action.payload.data
  state.error = errorMsgCreator('Unable to fetch unassigned clients. ',data)
})

builder.addCase(assignClients.pending, (state, action) => {
  state.loading = true
})
builder.addCase(assignClients.fulfilled, (state, action) => {
state.loading = false
state.success = true
state.successMsg = 'Successfully assigned clients'
})
builder.addCase(assignClients.rejected, (state, action) => {
  state.loading = false
  let data = action.payload.data
  state.error = errorMsgCreator('Unable to assign client. ',data)
  
})
builder.addCase(removeClient.pending, (state, action) => {
  state.loading = true
})
builder.addCase(removeClient.fulfilled, (state, action) => {
state.loading = false
state.success = true
state.successMsg = 'Successfully removed client'
})
builder.addCase(removeClient.rejected, (state, action) => {
  state.loading = false
  let data = action.payload.data
  state.error = errorMsgCreator('Unable to remove client. ',data)
})
  },
})

export const { resetStore} = userSlice.actions

export default userSlice.reducer