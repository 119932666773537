import React, { useState, useEffect } from 'react';

const FileUpload = ({file,setFile,inputId, allowMultiple}) => {

  const handleFileChange = (event) => {
    let selectedFile;
    if(allowMultiple){
      selectedFile = event.target.files;
    }else{
      selectedFile = event.target.files[0];
    }
    setFile(selectedFile);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    let droppedFile;
    if(allowMultiple){
      droppedFile = event.dataTransfer.files;
    }else{
      droppedFile = event.dataTransfer.files[0];
    }
    setFile(droppedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    console.log(file);
  }, [file]);

  return (
    <div
      className="border-2 border-dashed border-gray-300 rounded-lg p-6 h-[15rem] flex flex-col items-center justify-center text-center bg-white cursor-pointer hover:border-blue-400 hover:bg-blue-50 transition-all"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <input
        id={`file-upload-${inputId && inputId}`}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        {...(allowMultiple ? { multiple: true } : {})}
      />
      <label htmlFor={`file-upload-${inputId && inputId}`} className="cursor-pointer">
        {!file && <p className="text-gray-600">
          Drag & Drop your file or{' '}
          <span className="text-blue-500 underline">Browse</span>
        </p>}
      </label>
      {file && (
        <div className="mt-4 text-sm text-gray-600">
          <p className='font-semibold text-blue-400 text-lg'>{file.name}</p>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
