export default function Icon(props) {
   const {
    imageUrl,
    title,
    onClickFunc,
    wrapper: Wrapper,
    wrapperProps,
    isWrapper = false,
    className
} = props
   
    const handleClick = () => {
        if (typeof onClickFunc == 'function') {
            onClickFunc()
        }
    }

    return (
        <div className={`col-md-2 col-sm-4 col-lg-2 text-center ${className ? className : ''}`} onClick={handleClick}>
            { isWrapper ? ( 
                        <Wrapper {...wrapperProps}>
                        <img alt={title} src={imageUrl} className="w-50 border border-radius-1 p-2"/>
                        <h6 className="mb-2">{title}</h6>
                        </Wrapper>
                    ) : (<div className="flex flex-col justify-center">
                            <img alt={title} src={imageUrl} className="w-20 block mx-auto"/>
                            <h6 className="">{title}</h6>
                        </div>
                    
                    )
         }
        </div>
    )
}