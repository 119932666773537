import {string, number,object} from 'yup';
import moment from 'moment';

export const ROUTES = {
    LIST_CLIENT: (page_number) => `/api/v1/client/list/${page_number}`,
    CREATE_CLIENT: "/api/v1/client/create",
    GET_SINGLE_CLIENT: (id) => `/api/v1/client/view/${id}`,
    DELETE_CLIENT: (id) => `/api/v1/client/delete/${id}`,
    UPDATE_CLIENT: (id) => `/api/v1/client/update/${id}`
}

export const ACTION_TYPES = {
    LIST_CLIENTS: 'LIST_CLIENTS'
}

export const CREATE_CLIENT_SCHEMA = object().shape({
    client_name: string().required("Full Name is required"),
    gstin: string().required("GST Number is required"),
    phone : number().transform((value) => Number.isNaN(value) ? null : value )
    .nullable().min(10),
    email: string().email(),
    city: string(),
    state: string(),
    country: string()
})

export const TABLE_HEADERS = [
    {
        id: 'client_name',
        name: 'CLIENT NAME'
    },
    {
        id: 'gstin',
        name: 'GST NUMBER',
    },
    {
        id: 'updated_date',
        name: 'LAST UPDATED',
        modifier: (date) => moment(date).format('YYYY-MM-DD')
    }
]


export const CREATE_CLIENT_FIELDS = {
        client_name: { name: 'client_name', placeholder: 'Full Name', type: 'text', label: 'Full Name'} ,
        gstin: { name: 'gstin', placeholder: 'GST Number', type: 'text', label: 'GST Number'},
        phone : { name: 'phone', placeholder: 'Phone Number', type: 'text', label: 'Phone Number'},
        email: { name: 'email', placeholder: 'Email', type: 'text', label: 'Email'},
        city: { name: 'city', placeholder: 'City', type: 'text', label: 'City'},
        state: { name: 'state', placeholder: 'State', type: 'text', label: 'State'},
        country: { name: 'country', placeholder: 'Country', type: 'text', label: 'Country'},
}