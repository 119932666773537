import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';


function CreateNewPeriod(props) {
    const { 
        handleSubmit,
        fields,
        errors,
        show,
        onHideFunc,
        values,
        isUpdate = false
     } = props;

     const [data, setData] = useState({})
    useEffect(() => {
        setData(values)
    },[values])

    const handleChange = (e, field) => {
        const fieldData = {...data};
        fieldData[field] = e.target.value;
        setData(fieldData)
    }

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" show={show}>
        <Modal.Header closeButton onHide={onHideFunc}>
            <Modal.Title id="contained-modal-title-vcenter">
            {!isUpdate ? 'Create Period': 'Update Period' }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
            <div style={{minHeight: "400px"}} >
                <div className="sign-user_card">   
                     <form className="login-content" onSubmit={(e) => handleSubmit(e, isUpdate)}>
                        <div className="row">
                         { !!fields && (
                            Object.keys(fields).map((field) => {
                                return (<div className="col-lg-12" key={field}>
                                    <div className="floating-label form-group">
                                        <input 
                                            className="floating-input form-control"
                                            name={fields[field]['name']}
                                            type={fields[field]['type']} 
                                            placeholder=" "
                                            value={data[field] ? data[field] : ''}
                                            onChange={(e) => handleChange(e, field)} 
                                        />
                                        <label>{fields[field]['label']}</label>
                                        {errors[field] && (<span class="error text-danger">{errors[field]}</span>)}
                                    </div>
                                 </div>)
                            })
                         ) }
                           
                        </div>
                        <button type="submit" className="btn btn-primary">{!isUpdate ? 'Create': 'Update' } &raquo;</button>
                     </form>
                  </div>
                 </div> 
        </Modal.Body>
        </Modal>    
    )
}

export default CreateNewPeriod;